import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  let date = new Date();
  return (
    <footer id="footer">
      <div className="container">
        <div className="copyright">
          Copyright © {date.getFullYear()} . Tous droits réservés | Développé
          par
          <Link
            style={{
              color: "#65a804",
              textDecoration: "none",
              fontWeight: "bold",
            }}
            to="https://www.jarstechnologies.com/"
            target="_blank"
          >
            {" "}
            JARS TECHNOLOGIES INTERNATIONAL
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
