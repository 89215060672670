import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import LandingPage from "./components/LandingPage.js";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
// import Declare from './components/declare/acceuil/DeclareAcceuil.js';
import AssistanceContainer from "./components/assistance/AssistanceContainer.js";
import FaqContainer from "./components/assistance/faq/FaqContainer.js";
import AlertContent from "./components/alertContent/AlertContent.js";
import Login from "./components/suivre/login/Login.js";
import GeneCode from "./components/suivre/login/GeneCode.js";
import SignupP from "./components/declare/signup/SignupP.js";
import PieceHome from "./components/suivre/pieces/PieceHome.js";
import SuivreHome from "./components/suivre/home/SuivreHome.js";
import CourierContainer from "./components/suivre/courier/CourierContainer.js";
import AllmailContainer from "./components/suivre/courier/AllmailContainer.js";
import React, { useEffect } from "react";
import { useTour } from "@reactour/tour";
import steps from "./steps.js";
import SuccessContainer from "./components/declare/individuelles/SuccessContainer.js";
import PdfTest from "./components/declare/individuelles/PdfTest.js";
import TypeHome from "./components/declare/recherche/type/TypeHome.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CourierSinistreMain from "./components/suivre/courier/courier-sinistre/CourierSinistreMain";
import RdvContainer from "./components/assistance/rdv/RdvContainer";
import ProductionPieceContainer from "./components/assistance/guide/ProductionPieceContainer";
import TutorialContainer from "./components/assistance/guide/TutorialContainer";
import HistoryContainer from "./components/suivre/history/HistoryContainer";
import RdvSuccess from "./components/assistance/rdv/RdvSuccess";

function App() {
  let location = useLocation();
  const { setSteps, setCurrentStep } = useTour();
  useEffect(() => {
    setCurrentStep(0);
    if (location === "/") {
      setSteps([
        {
          selector: '[data-tour="step-page"]',
          content: "text page",
        },
      ]);
    } else if (location === "/declare") {
      setSteps([
        {
          selector: '[data-tour="step-page-2"]',
          content: "text page 2",
        },
        {
          selector: '[data-tour="step-page-3"]',
          content: "text page 3",
        },
      ]);
    } else {
      setSteps(steps);
    }
  }, [location, setCurrentStep, setSteps]);

  return (
    <div>
      <ToastContainer />
      <Header />
      <Routes>
        <Route path="/" element={<LandingPage />} />

        {/* <Route path='declare' element={<Declare />} /> */}
        <Route path="search" element={<TypeHome />} />
        <Route path="signup" element={<SignupP />} />
        <Route path="success" element={<SuccessContainer />} />

        <Route path="login" element={<Login />} />
        <Route path="newcode" element={<GeneCode />} />
        <Route path="suivrehome" element={<SuivreHome />} />
        <Route path="piece" element={<PieceHome />} />
        <Route path="courierdecla" element={<CourierContainer />} />
        <Route path="couriersini" element={<CourierSinistreMain />} />
        <Route path="allmailContainer" element={<AllmailContainer />} />
        <Route path="history" element={<HistoryContainer />} />

        <Route path="assistance" element={<AssistanceContainer />} />
        <Route path="faq" element={<FaqContainer />} />
        <Route path="rdv" element={<RdvContainer />} />
        <Route path="rdvsuccess" element={<RdvSuccess />} />
        <Route path="guide" element={<ProductionPieceContainer />} />
        <Route path="tutorial" element={<TutorialContainer />} />
        <Route path="pdftest" element={<PdfTest />} />

        <Route path="alertContent" element={<AlertContent />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
