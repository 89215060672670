import axios from "axios";
import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { AppContext } from "../../../context/ContextApi";
import "./piecehome.css";
import "./tablegrid.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GiCheckMark } from "react-icons/gi";
import { TiWarning } from "react-icons/ti";
import global_variables from "../../controllers/globalVar.js";
import UnicForm from "./form/UnicForm.js";
import MultiForm from "./form/MultiForm.js";
import { AiOutlineArrowLeft } from "react-icons/ai";
import NewPiece from "./NewPiece.js";
import { GoAlert } from "react-icons/go";
import { FcDocument } from "react-icons/fc";
import AlertContent from "../../alertContent/AlertContent.js";
import { FaThumbsUp } from "react-icons/fa";
import { GrView } from "react-icons/gr";
import piecesList from "../../../data/listePiecesRequises.json";

const TableGrid = () => {
  const {
    individuel,
    individuelNextStep,
    individuelPreviousStep,
    fileName,
    fileName2,
    fileName3,
    isCheck,
    selectedOption,
    setSelectedOption,
    selectedItem,
    setSelectedItem,
    declareAcceuil,
    setDeclareAcceuil,
    successful,
    setSuccessful,
    message,
    setMessage,
    showAlert,
    alert,
    researchResult,
    setSearchResult,
  } = useContext(AppContext);
  const { showModal, setShowModal } = useContext(AppContext);
  const [requis, setRequis] = useState([]);
  const [autres, setAutres] = useState([]);
  const [nbPiece, setNombrePiece] = useState(0);
  const [reçu, setReçu] = useState([]);
  const [attent, setAttent] = useState([]);
  const [ischeck, setCheck] = useState(false);
  const [noNeedCheck, setNoNeedCheck] = useState(false);
  const [loading, setLoading] = useState(true);
  const [targetNumber, setTargetNumber] = useState(5);

  //   import React, { useState, useEffect } from 'react';

  // function Countdown() {
  //   const [targetNumber, setTargetNumber] = useState(20);

  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       setTargetNumber(prevNumber => prevNumber - 4);
  //     }, 24 * 60 * 60 * 1000); // Update every 24 hours

  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }, []);

  //   return (
  //     <div>
  //       {targetNumber <= 0 ? (
  //         <p>Countdown completed!</p>
  //       ) : (
  //         <p>Target number: {targetNumber}</p>
  //       )}
  //     </div>
  //   );
  // }

  // export default Countdown;

  //getting data from localstorage
  let data = JSON.parse(localStorage.getItem("user"));
  let numDeclaration = useMemo(() => [data.numero_declaration], [
    data.numero_declaration,
  ]);

  const handlePreviewTest = () => {
    console.log("preview test");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTargetNumber((prevNumber) => prevNumber - 4);
    }, 24 * 60 * 60 * 1000); // Update every 24 hours

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    // var config = {
    //   method: "post",
    //   maxBodyLength: Infinity,
    //   url: `${
    //     global_variables().SINISTRE_CARE_WEBSERVICE
    //   }/list-pieces-required?numero_declaration=${numDeclaration}`,
    //   headers: {},
    // };

    // axios(config)
    // .then(function(response) {
    var response = piecesList;
    setLoading(false);
    if (response.data.status === false && response.data.length === 0) {
      setRequis(response.data);
    } else {
      // setRequis(response.data.data.pieces_requises);
      setNombrePiece(response.data.nb_pieces_requises);
      console.log("pieces_requises", response.data.pieces_requises);

      console.log("autres_pieces", response.data.autres_pieces);

      response.data.autres_pieces.forEach((element) => {
        element.statut = 1;
        element.type = "autre";
      });
      setAutres(response.data.autres_pieces);
      let GlobalData = [
        ...response.data.autres_pieces,
        ...response.data.pieces_requises,
      ];

      console.log("response.data.data", response.data);
      console.log("newArray", response.data.autres_pieces);
      console.log("GlobalData", GlobalData);
      setRequis(GlobalData);
    }
    // })
    // .catch(function(error) {
    //   setLoading(false);
    //   // toast.error(
    //   //   "Désolé! Le service que vous tentez de joindre ne répond pas. Veuillez réessayer plus tard.",
    //   //   {
    //   //     position: "top-center",
    //   //     autoClose: 10000,
    //   //     hideProgressBar: false,
    //   //     closeOnClick: true,
    //   //     pauseOnHover: true,
    //   //     draggable: true,
    //   //     progress: undefined,
    //   //     theme: "dark",
    //   //   }
    //   // );
    //   console.log(error);
    // });
  }, [numDeclaration]);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  console.log("requis", requis);
  console.log("nbrpiece", nbPiece);

  const handlepiece = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    let file = e.target.files[0];
    const base64 = await convertBase64(file);
  }, []);

  //function d'affichage d'icon selon le status
  const showCheck = (requi) => {
    if (requi.statut === 0) {
      return (
        <>
          <TiWarning color="#e8e80e" size={25} /> En attente
        </>
      );
    } else if (requi.statut === 1) {
      return (
        <>
          <GiCheckMark color="#059945" size={25} /> Transmis
        </>
      );
    }
  };
  //function d'affichage d'icon selon le status
  const showCheck2 = (requi) => {
    if (requi.statut === 0) {
      return (
        <>
          <TiWarning color="#e8e80e" size={25} /> En attente
        </>
      );
    } else if (requi.statut === 1) {
      return (
        <>
          <GiCheckMark color="#059945" size={25} /> Transmis
        </>
      );
    }
  };
  //function d'affichage le contenu
  const showContent = (requi) => {
    if (requi.statut === 1 && requi.url) {
      return (
        <Link
          to={requi.url}
          target="_blank"
          type="button"
          title="courriers"
          className="justify-items-center no-underline relative text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 rounded-lg px-3 py-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
        >
          <span className="flex">
            <FcDocument size={25} className="mr-1" /> Afficher
          </span>
        </Link>
      );
    }
  };
  //function d'affichage le contenu d'autres fichier
  const showContent2 = (requi) => {
    if (requi.statut === 1 && requi.url) {
      return (
        <Link
          to={requi.url}
          target="_blank"
          type="button"
          title="courriers"
          className="no-underline relative text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 rounded-lg px-3 py-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
        >
          <span className="flex">
            <FcDocument size={25} className="mr-1" /> Afficher
          </span>
        </Link>
      );
    }
  };
  //function d'affichage de formulaire selon le nombre de pieces requises
  const ShowForm = (requi, index) => {
    if (requi.statut === 0 && requi.nombre === "plusieurs") {
      return <MultiForm id={requi.id} />;
    } else if (requi.statut === 1 && requi.nombre === "plusieurs") {
      return null;
    } else if (requi.nombre === "unique" && requi.statut === 1) {
      return null;
    } else {
      return <UnicForm id={requi.id} type={requi.type} />;
    }
  };
  if (loading) return <AlertContent />;
  //   console.log("requis", requis[requis.length - 1].statut === 1);
  return (
    <>
      <div
        className="alert alert-danger mt-sm-0 mt-md-5 mt-lg-5 animation col-sm-12 col-md-12 col-lg-12"
        role="alert"
      >
        <div className="flex justify-content-center align-content-center ">
          <GoAlert size={22} className="m-1 d-none d-md-block" />{" "}
          <strong className="attention-te">ATTENTION!</strong> &ensp;
        </div>
        <h5 className="d-block d-md-flex justify-center responsive-alignment-attention items-center attention-text">
          LES PIÈCES ORIGINALES SONT À DÉPOSER EN AGENCE APRÈS LEUR TRANSMISSION
          EN LIGNE.
        </h5>
      </div>
      {requis.length === 0 ? (
        <div className="card-body py-3 rounded-lg">
          <h4 className="text-4xl p-2 text-white responsive-alignment-header font-mono leading-tight text-center">
            {/* LISTES DES PIECES REQUISES */}
            LISTES DES PIECES
          </h4>
          <div className="row flex justify-around responsive-alignment-container">
            <div className="col-sm-12 col-md-12 col-lg-6  flex justify-center  items-center  px-2 ">
              <h4 className="custom-with-label  responsive-alignment  text-xl font-serif font-medium text-gray-300 md:text-xl dark:text-gray-400">
                NUMERO DECLARATION :
              </h4>
              <span className="text-xl text-white custom-with-label font-mono mx-4 font-semibold">
                {data.numero_declaration}
              </span>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6  flex justify-center  items-center  px-2 ">
              <h4 className="custom-with-label  responsive-alignment  text-xl font-serif font-medium text-gray-300 md:text-xl dark:text-gray-400">
                DECLARANT :
              </h4>
              <span className="text-xl text-white custom-with-label font-mono mx-4 font-semibold">
                {data.declarant}
              </span>
            </div>
          </div>
        </div>
      ) : requis.length !== 0 ? (
        <div className="card-body py-3 rounded-lg">
          <h4 className="text-4xl p-2 text-white responsive-alignment-header font-mono leading-tight text-center">
            {/* LISTES DES PIECES REQUISES */}
            LISTES DES PIECES
          </h4>
          <div className="row flex justify-around responsive-alignment-container">
            <div className="col-sm-12 col-md-12 col-lg-6  flex justify-center  items-center  px-2 ">
              <h4 className="custom-with-label  responsive-alignment  text-xl font-serif font-medium text-gray-300 md:text-xl dark:text-gray-400">
                NUMERO DECLARATION :
              </h4>
              <span className="text-xl text-white custom-with-label font-mono mx-4 font-semibold">
                {data.numero_declaration}
              </span>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6  flex justify-center  items-center  px-2 ">
              <h4 className="custom-with-label  responsive-alignment  text-xl font-serif font-medium text-gray-300 md:text-xl dark:text-gray-400">
                DECLARANT :
              </h4>
              <span className="text-xl text-white custom-with-label font-mono mx-4 font-semibold">
                {data.declarant}
              </span>
            </div>
            {nbPiece > 0 && requis[requis.length - 1].statut === 1 ? (
              <div className="col-12 flex justify-center  items-center  px-2 ">
                {targetNumber <= 0 ? (
                  <h5 className="text-xl p-2 text-rose-700 responsive-alignment-header font-mono leading-tight text-center">
                    Votre déclaration de sinistre est en cours de traitement.
                  </h5>
                ) : (
                  <div className="alert alert-primary" role="alert">
                    Félicitations! Votre déclaration de sinistre est en cours de
                    traitement. Vous aurez une suite dans un délai de{" "}
                    {targetNumber} jours ouvrés
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      ) : autres.length !== 0 ? (
        <div className="card-body py-3 rounded-lg">
          <h4 className="text-4xl p-2 text-white responsive-alignment-header font-mono leading-tight text-center">
            {/* LISTES DES PIECES REQUISES */}
            LISTES DES PIECES
          </h4>
          <div className="row flex justify-around responsive-alignment-container">
            <div className="col-sm-12 col-md-12 col-lg-6  flex justify-center  items-center  px-2 ">
              <h4 className="custom-with-label  responsive-alignment  text-xl font-serif font-medium text-gray-300 md:text-xl dark:text-gray-400">
                NUMERO DECLARATION :
              </h4>
              <span className="text-xl text-white custom-with-label font-mono mx-4 font-semibold">
                {data.numero_declaration}
              </span>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6  flex justify-center  items-center  px-2 ">
              <h4 className="custom-with-label  responsive-alignment  text-xl font-serif font-medium text-gray-300 md:text-xl dark:text-gray-400">
                DECLARANT :
              </h4>
              <span className="text-xl text-white custom-with-label font-mono mx-4 font-semibold">
                {data.declarant}
              </span>
            </div>
          </div>
        </div>
      ) : null}
      <div className="row my-3 relative">
        <div className="col-12 table-responsive">
          {nbPiece === 0 && autres.length === 0 ? (
            <NewPiece />
          ) : nbPiece > 0 ? (
            <table className="table table-sm table-nowrap min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="p-3 text-left text-gray-600 uppercase">#</th>
                  <th className="p-3 text-left text-gray-600 uppercase">
                    Pièce
                  </th>
                  <th className="p-3 text-left text-gray-600 uppercase">
                    Statut
                  </th>
                  {/* <th className="p-3 text-left text-gray-600 uppercase"></th> */}
                  <th className="p-3 text-left text-gray-600 uppercase">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="card-body tbody-mobile text-left">
                {requis?.map((requi, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {requi.libelle}{" "}
                        <strong className="text-yellow-500 ms-2">
                          ({requi.nature})
                        </strong>
                      </td>
                      <td className="text-nowrap status-container border-t border-gray-200 font-normal text-white text-gray-500">
                        <div
                          className={`target-${requi.id} status flex w-full justify-around`}
                        >
                          {showCheck(requi)}
                        </div>
                      </td>
                      {/* {requi.statut === 0 ? (
                    <td>
                      {" "}
                      <button
                        onClick={handlePreviewTest}
                        className="btn btn-success"
                      >
                        <GrView />
                      </button>
                    </td>
                  ) : (
                    <td></td>
                  )} */}
                      {requi.statut === 0 ? (
                        <td>{ShowForm(requi, index)}</td>
                      ) : (
                        <td> {showContent(requi, index)}</td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : nbPiece === 0 && autres.length !== 0 ? (
            <>
              <table className="table table-sm table-nowrap min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="p-3 text-left text-gray-600 uppercase">#</th>
                    <th className="p-3 text-left text-gray-600 uppercase">
                      Pièce
                    </th>
                    <th className="p-3 text-left text-gray-600 uppercase">
                      Statut
                    </th>
                    {/* <th className="p-3 text-left text-gray-600 uppercase"></th> */}
                    <th className="p-3 text-left text-gray-600 uppercase">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="card-body tbody-mobile text-left">
                  {autres?.map((autre, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {autre.libelle}{" "}
                          <strong className="text-yellow-500 ms-2">
                            ({autre.nature})
                          </strong>
                        </td>
                        <td className="text-nowrap status-container border-t border-gray-200 font-normal text-white text-gray-500">
                          <div
                            className={`target-${autre.id} status flex w-full justify-around`}
                          >
                            {showCheck2(autre)}
                          </div>
                        </td>
                        {/* {autre.statut === 0 ? (
                    <td>
                      {" "}
                      <button
                        onClick={handlePreviewTest}
                        className="btn btn-success"
                      >
                        <GrView />
                      </button>
                    </td>
                  ) : (
                    <td></td>
                  )} */}
                        <td>{showContent2(autre, index)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <NewPiece />
            </>
          ) : null}
        </div>
      </div>
      <div className="declare-btn flex justify-center items-center">
        <Link
          to="/suivrehome"
          type="button"
          className="return-btn relative flex recherche-btn justify-center items-center py-2 px-5 text-2xl font-medium rounded"
          style={{ textDecoration: "none" }}
        >
          <AiOutlineArrowLeft className="mr-2" /> Retour
        </Link>
      </div>
    </>
  );
};

//Félicitations! Vous venez de completer la transmission de tous les fichiers requis.Votre declaration sera traitée dans un délai de 5 jours ouvrés.
//apres la derniere transmission de fichier  afficher le delais de traitement et le lien des fichiers scannes dans un tableau.

export default TableGrid;
