import React, { useContext, useState } from "react";
import { AppContext } from "../../../context/ContextApi";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./individuel.css";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

const validationSchema = Yup.object().shape({
  civilite: Yup.string().required("Ce champ est requis"),
  declaNom: Yup.string().required("Ce champ est requis"),
  declaPrenom: Yup.string().required("Ce champ est requis"),
  qualite_declarant: Yup.string(),
  declaContact: Yup.string().required("Ce champ est requis"),
  autreContact: Yup.string(),
  declaAdressElectro: Yup.string(),
  declaAdressePost: Yup.string(),
});

const Declarant = () => {
  const { individuel, individuelNextStep, individuelPreviousStep } = useContext(
    AppContext
  );
  const [error, setError] = useState(false);
  const [message, setMessate] = useState("beginning");
  const handleSubmit = (values) => {
    individuelNextStep(values);
  };
  const handleBack = (values) => {
    individuelPreviousStep(values);
  };
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={individuel}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form>
          <h3 className="section-title section-title-mobile">
            INFORMATION SUR LE DECLARANT
          </h3>
          <div className="row flex cutom-box">
            <div className="col-12 custom-with">
              <label className="text-grey-darker text-xl leading-3 mb-2">
                CIVILITE <strong style={{ color: "#cc992d" }}>*</strong>
              </label>
              <div className="flex mb-2 civilite-mobile-container">
                <input
                  id="Monsieur"
                  name="civilite"
                  onChange={(e) => {
                    setFieldValue("civilite", e.target.id);
                    // console.log("civilite", e.target.id);
                  }}
                  type="radio"
                  className="btn-check"
                  autoComplete="off"
                />
                <label className="btn btn-outline-light m-2" htmlFor="Monsieur">
                  Monsieur
                </label>
                <input
                  id="Madame"
                  name="civilite"
                  onChange={(e) => {
                    setFieldValue("civilite", e.target.id);
                    // console.log("civilite", e.target.id);
                  }}
                  type="radio"
                  className="btn-check"
                  autoComplete="off"
                />
                <label className="btn btn-outline-light m-2" htmlFor="Madame">
                  Madame
                </label>
                <input
                  id="Mademoiselle"
                  name="civilite"
                  onChange={(e) => {
                    setFieldValue("civilite", e.target.id);
                    // console.log("civilite", e.target.id);
                  }}
                  type="radio"
                  className="btn-check"
                  autoComplete="off"
                />
                <label
                  className="btn btn-outline-light m-2"
                  htmlFor="Mademoiselle"
                >
                  Mademoiselle
                </label>
              </div>
              <p className="text-red-600 dark:text-red-500">
                {" "}
                {errors.civilite && touched.civilite ? errors.civilite : null}
              </p>
            </div>
          {/*</div>*/}
          {/*<div className="flex cutom-box">*/}
            <div className="col-md-4 custom-with">
              <label
                className="block text-grey-darker text-xl leading-3 mb-2"
                htmlFor="declaNom"
              >
                NOM <strong style={{ color: "#cc992d" }}>*</strong>
              </label>
              <Field
                className="appearance-none border custom-with-label rounded text-xl font-mono w-full p-2 text-grey-darker"
                name="declaNom"
                id="declaNom"
                type="text"
                placeholder="NOM"
              />
              <p className=" text-red-600 dark:text-red-500">
                {" "}
                {errors.declaNom && touched.declaNom ? errors.declaNom : null}
              </p>
            </div>
            <div className="col-md-4 custom-with">
              <label
                className="block text-grey-darker text-xl leading-3 mb-2"
                htmlFor="declaPrenom"
              >
                PRENOMS <strong style={{ color: "#cc992d" }}>*</strong>
              </label>
              <Field
                className="appearance-none border custom-with-label rounded text-xl font-mono w-full  p-2 text-grey-darker"
                name="declaPrenom"
                id="declaPrenom"
                type="text"
                placeholder="PRENOMS"
              />
              <p className="text-red-600 dark:text-red-500">
                {" "}
                {errors.declaPrenom && touched.declaPrenom
                  ? errors.declaPrenom
                  : null}
              </p>
            </div>
            <div className="col-md-4 custom-with">
              <label
                className="block text-grey-darker text-xl leading-3 mb-2"
                htmlFor="qualite_declarant"
              >
                LIEN AVEC LE DÉFUNT
                <strong style={{ color: "#cc992d" }}>*</strong>
              </label>
              <select
                className="appearance-none border custom-with-label rounded text-xl font-mono w-full p-2 text-grey-darker"
                id="qualite_declarant"
                name="qualite_declarant"
                placeholder="QUALITE"
                onChange={(e) => {
                  setFieldValue("qualite_declarant", e.target.value);
                }}
              >
                <option
                  value={true}
                  label="Sélectionner votre lien avec le défunt"
                ></option>
                <option value="ENFANT" label="ENFANT"></option>
                <option value="PÈRE/MÈRE" label="PÈRE/MÈRE"></option>
                <option value="CONJOINT(E)" label="CONJOINT(E)"></option>
                <option value="EMPLOYEUR" label="EMPLOYEUR"></option>
                <option value="AUTRE" label="AUTRE" />
              </select>
              <p className="text-red-600 dark:text-red-500">
                {" "}
                {errors.qualite_declarant && touched.qualite_declarant
                  ? errors.qualite_declarant
                  : null}
              </p>
            </div>
          {/*</div>*/}
          {/*<div className="flex cutom-box">*/}
            <div className="col-md-6 custom-with">
              <label
                className="block text-grey-darker text-xl leading-3 mb-2"
                htmlFor="declaContact"
              >
                CONTACTS TELEPHONIQUES{" "}
                <strong style={{ color: "#cc992d" }}>*</strong>
              </label>
              <Field
                className="appearance-none border custom-with-label rounded text-xl font-mono w-full p-2 text-grey-darker"
                name="declaContact"
                id="declaContact"
                type="text"
                placeholder="CONTACTS TELEPHONIQUES"
              />
              <p className=" text-red-600 dark:text-red-500">
                {" "}
                {errors.declaContact && touched.declaContact
                  ? errors.declaContact
                  : null}
              </p>
            </div>
            <div className="col-md-6 custom-with">
              <label
                className="block text-grey-darker text-xl leading-3 mb-2"
                htmlFor="autreContact"
              >
                AUTRE CONTACT{" "}
              </label>
              <Field
                className="appearance-none border custom-with-label rounded text-xl font-mono w-full p-2 text-grey-darker"
                name="autreContact"
                id="autreContact"
                type="text"
                placeholder="AUTRE CONTACT"
              />
              <p className=" text-red-600 dark:text-red-500">
                {" "}
                {errors.declaContact && touched.declaContact
                  ? errors.declaContact
                  : null}
              </p>
            </div>
          {/*</div>*/}
          {/*<div className="flex cutom-box">*/}
            <div className="col-md-6 custom-with">
              <label
                className="block text-grey-darker text-xl font-mono leading-3 mb-2"
                htmlFor="declaAdressElectro"
              >
                ADRESSE ELECTRONIQUE
              </label>
              <Field
                className="appearance-none border custom-with-label rounded text-xl font-mono w-full  p-2 text-grey-darker"
                name="declaAdressElectro"
                id="declaAdressElectro"
                type="email"
                placeholder="ADRESSE ELECTRONIQUE"
              />
              <p className="text-red-600 dark:text-red-500">
                {errors.declaAdressElectro && touched.declaAdressElectro
                  ? errors.declaAdressElectro
                  : null}
              </p>
            </div>
            <div className="col-md-6 custom-with">
              <label
                className="block text-grey-darker text-xl leading-3 mb-2"
                htmlFor="declaAdressePost"
              >
                ADRESSE POSTALE
              </label>
              <Field
                className="appearance-none border custom-with-label rounded text-xl font-mono w-full  p-2 text-grey-darker"
                name="declaAdressePost"
                id="declaAdressePost"
                type="text"
                placeholder="ADRESSE POSTALE"
              />
              <p className="text-red-600 dark:text-red-500">
                {" "}
                {errors.declaAdressePost && touched.declaAdressePost
                  ? errors.declaAdressePost
                  : null}
              </p>
            </div>
            {/* <div className="w-1/3 mx-2 custom-with"> */}
            {/* <label className="block text-grey-darker text-xl leading-3 mb-2" htmlFor="declaAdressePost">ADRESSE POSTALE</label>
                            <Field className="appearance-none border custom-with-label rounded text-xl font-mono w-full  p-2 text-grey-darker" name='declaAdressePost' id="declaAdressePost" type="text" placeholder="ADRESSE POSTALE" />
                            <p className="text-red-600 dark:text-red-500"> {errors.declaAdressePost && touched.declaAdressePost ? errors.declaAdressePost : null}</p> */}
            {/* </div> */}
          </div>
          {/* <div className="flex cutom-box">
                        
                    </div> */}
          <div className="flex mt-3 justify-between align-center text-center btn-container souscripteur-btn-mobile">
            <div className="w-1/2 mx-2 flex justify-center souscripteur-btn-back">
              <button
                onClick={() => handleBack(values)}
                type="button"
                className="return-btn flex recherche-btn justify-center items-center px-3 py-2 text-2xl font-medium rounded"
              >
                <AiOutlineArrowLeft className="mr-2" /> Précédent
              </button>
            </div>
            <div className="w-1/2 mx-2 flex justify-center">
              <button
                type="submit"
                className="flex recherche-btn justify-center items-center px-3 py-2 text-2xl font-medium text-white rounded border border-blue-700 focus:ring-4 focus:outline-none focus:bg-amber-400 hover:bg-amber-400 focus:bg-amber-400"
              >
                Suivant <AiOutlineArrowRight className="ml-2" />
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Declarant;
