// import { BiSave } from "react-icons/bi";
// import { IoMdTrash } from "react-icons/io";
// import { BsPlus } from "react-icons/bs";
// import React, {
//   useContext,
//   useState,
//   useCallback,
//   useRef,
//   useMemo,
// } from "react";
// import "./multiple.css";
// import Loader from "../../../loading/Loading.js";
// import axios from "axios";
// import { toast } from "react-toastify";
// import global_variables from "../../../controllers/globalVar.js";
// import { Viewer, Worker } from "@react-pdf-viewer/core";
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// import { GrView } from "react-icons/gr";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import Modal from "@mui/material/Modal";
// import CloseIcon from "@mui/icons-material/Close";
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// import Button2 from "react-bootstrap/Button";
// import Modal2 from "react-bootstrap/Modal";

// const style = {
//   margin: 12,
//   height: 700,
//   width: 800,
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   p: 4,
// };

// const MultiForm = ({ id }) => {
//   const newplugin = defaultLayoutPlugin();
//   const [inputs, setInputs] = useState([
//     { beneficiaire: "", nature_fichier: "", file: null },
//   ]);
//   const [show, setShow] = useState(true);
//   const [done, setDone] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [pdfFile, setPDFfile] = useState(null);
//   const [viewPdf, setViewPdf] = useState(null);
//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [showPreview, setShowPreview] = useState(false);
//   const [showModal, setShowModal] = useState(false);

//   const handleModalClose = () => {
//     setShowModal(false);
//     setSelectedFile(null);
//   };

//   const handlePreview = (e) => {
//     e.preventDefault();
//     if (pdfFile !== null) {
//       setViewPdf(pdfFile);
//       setOpen(true);
//     } else {
//       setViewPdf(null);
//     }
//     setShowPreview(true);
//   };
//   const fileType = ["application/pdf"];

//   const PreviewComponent = () => {
//     if (selectedFile) {
//       // Handle the file type and display accordingly
//       //   JPG, PNG, TIFF, BMP
//       if (
//         selectedFile.type.startsWith("image/") ||
//         selectedFile.type.startsWith("jpg/") ||
//         selectedFile.type.startsWith("png/") ||
//         selectedFile.type.startsWith("tiff/") ||
//         selectedFile.type.startsWith("bmp/")
//       ) {
//         const imageUrl = URL.createObjectURL(selectedFile);
//         setShowModal(true);
//         return (
//           // <div className="mb-4 mt-2">
//           //   <img src={imageUrl} alt="Preview" />
//           // </div>
//           //   <div>
//           //   <input type="file" onChange={handleImageChange} />
//           //   <button onClick={handleUpload}>Upload</button>
//           // </div>
//           <>
//             {/* //   <Button2 variant="primary" onClick={handleShowModal}>
//           //     Launch demo modal
//           //   </Button2> */}

//             <Modal2 show={showModal}>
//               <Modal2.Body>
//                 {" "}
//                 <img src={imageUrl} alt="Preview" />
//               </Modal2.Body>
//               <Modal2.Footer>
//                 <Button2 variant="secondary" onClick={handleModalClose}>
//                   Close
//                 </Button2>
//               </Modal2.Footer>
//             </Modal2>
//           </>
//           // </div>
//         );
//       } else if (selectedFile && fileType.includes(selectedFile.type)) {
//         let reader = new FileReader();
//         reader.readAsDataURL(selectedFile);
//         reader.onload = () => {
//           setPDFfile(reader.result);
//         };

//         return (
//           <div>
//             <Modal
//               open={open}
//               onClose={handleClose}
//               aria-labelledby="modal-modal-title"
//               aria-describedby="modal-modal-description"
//             >
//               <Box sx={style}>
//                 <div
//                   style={{ display: "flex", justifyContent: "space-between" }}
//                 >
//                   <Typography
//                     id="modal-modal-title"
//                     variant="h6"
//                     component="h2"
//                   >
//                     Visualisation
//                   </Typography>
//                   <CloseIcon
//                     onClick={handleClose}
//                     style={{ cursor: "pointer" }}
//                   />
//                 </div>
//                 <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
//                   {viewPdf && (
//                     <>
//                       <Viewer fileUrl={viewPdf} pugins={[newplugin]} />
//                     </>
//                   )}
//                   {!viewPdf && <>No PDF</>}
//                 </Worker>
//               </Box>
//             </Modal>
//           </div>
//         );
//       } else {
//         return <p>Preview not available for this file type.</p>;
//       }
//     } else {
//       return null; // No file selected, don't render the preview component
//     }
//   };

//   let data = JSON.parse(localStorage.getItem("user"));
//   let numDeclaration = useMemo(() => [data.data.numero_declaration], [
//     data.data.numero_declaration,
//   ]);

//   const handleInputChange = (index, event) => {
//     const { name, value } = event.target;
//     const newInputs = [...inputs];
//     newInputs[index][name] = value;
//     setInputs(newInputs);
//   };

//   const handleFileChange = async (index, event) => {
//     let eventFile = event.target.files[0];
//     setSelectedFile(eventFile);
//     const newInputs = [...inputs];
//     newInputs[index].file = await convertBase64(event.target.files[0]);
//     setInputs(newInputs);
//   };

//   const handleAddInput = () => {
//     const newInputs = [
//       ...inputs,
//       { beneficiaire: "", nature_fichier: "", file: null },
//     ];
//     setInputs(newInputs);
//   };

//   const handleRemoveInput = (index) => {
//     const newInputs = [...inputs];
//     newInputs.splice(index, 1);
//     setInputs(newInputs);
//   };

//   const convertBase64 = (file) => {
//     return new Promise((resolve, reject) => {
//       const fileReader = new FileReader();
//       fileReader.readAsDataURL(file);

//       fileReader.onload = () => {
//         resolve(fileReader.result);
//       };
//       fileReader.onerror = (error) => {
//         reject(error);
//       };
//     });
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     let piecess = [];
//     inputs.forEach((each, index) => {
//       piecess.push([
//         `${id}`,
//         `${1}`,
//         `${each.beneficiaire}`,
//         "scanne",
//         `${each.file}`,
//       ]);
//     });

//     var data = JSON.stringify({
//       numero_declaration: `${numDeclaration}`,
//       pieces_assure: [],
//       pieces_beneficiaire: piecess,
//     });

//     setLoading(true);
//     var config = {
//       method: "post",
//       maxBodyLength: Infinity,
//       url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/add-documents`,
//       headers: {
//         "Content-Type": "application/json",
//       },
//       data: data,
//     };

//     axios(config)
//       .then((response) => {
//         setLoading(false);
//         toast.success("Féliciations! Fichier envoyer.", {
//           position: "top-center",
//           autoClose: 1000000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         });
//         setDone(true);
//         setShow(false);
//       })
//       .catch((error) => {
//         setLoading(false);
//         toast.error(
//           "Désolé! Le service que vous tentez de joindre ne répond pas. Veuillez réessayer plus tard.",
//           {
//             position: "top-center",
//             autoClose: 1000000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "dark",
//           }
//         );
//         console.log(error);
//       });
//   };

//   return (
//     <>
//       {loading ? (
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Loader />
//         </div>
//       ) : done ? (
//         <p>Fichier en cours de Traitement </p>
//       ) : show ? (
//         <form onSubmit={handleSubmit}>
//           {inputs.map((input, index) => (
//             <div key={index} className="flex">
//               <div>
//                 <button onClick={handlePreview} className="btn btn-success">
//                   <GrView />
//                 </button>
//               </div>
//               <input
//                 className="relative text-black bg-gray-200 px-2 py-1 mx-1 rounded outline-none"
//                 type="text"
//                 name="beneficiaire"
//                 placeholder="Beneficiaire"
//                 value={input.beneficiaire}
//                 required
//                 onChange={(event) => handleInputChange(index, event)}
//               />

//               <input
//                 className="relative text-black bg-gray-200 px-2 py-1 mx-1 rounded outline-none"
//                 type="file"
//                 name="file"
//                 required
//                 onChange={(event) => handleFileChange(index, event)}
//               />
//               {showPreview && <PreviewComponent />}
//               <button
//                 className="relative"
//                 type="button"
//                 onClick={() => handleRemoveInput(index)}
//               >
//                 <IoMdTrash size={30} color="#e88080" />
//               </button>
//             </div>
//           ))}
//           <div className="flex justify-between items-center">
//             <button
//               onClick={handleAddInput}
//               type="button"
//               className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-2 py-1 mt-2 mx-1 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
//             >
//               <span className="relative flex justify-center items-center">
//                 <BsPlus size={27} color="#fbbf24" className="mr-1" /> Ajouter
//                 Autre
//               </span>
//             </button>
//             <button
//               className="relative text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-2 py-1 mt-2 mx-1 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
//               type="submit"
//             >
//               <span className="flex justify-center items-center">
//                 <BiSave size={27} color="#fbbf24" className="mr-1" />
//                 Transmettre
//               </span>
//             </button>
//           </div>
//         </form>
//       ) : null}
//     </>
//   );
// };

// export default MultiForm;

// import React, { useState, useContext, useMemo } from "react";
// import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import global_variables from "../../controllers/globalVar.js";
// import { IoIosSend, IoMdTrash } from "react-icons/io";
// import { AppContext } from "../../../context/ContextApi";
// import {
//   AiOutlineArrowLeft,
//   AiOutlineArrowRight,
//   AiOutlineFile,
//   AiFillPlusCircle,
// } from "react-icons/ai";
// import Loader from "../../loading/Loading.js";
// import { Viewer, Worker } from "@react-pdf-viewer/core";
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// import { GrView } from "react-icons/gr";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import Modal from "@mui/material/Modal";
// import CloseIcon from "@mui/icons-material/Close";
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// import Button2 from "react-bootstrap/Button";
// import Modal2 from "react-bootstrap/Modal";

// const style = {
//   margin: 12,
//   height: 700,
//   width: 800,
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   p: 4,
// };

// function ImageUpload() {
//   const newplugin = defaultLayoutPlugin();
//   const {
//     individuel,
//     individuelNextStep,
//     individuelPreviousStep,
//     setFileName,
//     setFileName2,
//     setFileName3,
//     staticFields,
//     setStaticFields,
//     staticSelectedCheckboxes,
//     setStaticSelectedCheckboxes,
//     dynamicSelectedCheckboxes,
//     setDynamicSelectedCheckboxes,
//     newPieceDynamicBox,
//     setNewPieceDynamicBox,
//     newPieceDynamicFields,
//     setNewPieceDynamicFields,
//   } = useContext(AppContext);
//   const [imageFields, setImageFields] = useState([]);
//   const [previewImages, setPreviewImages] = useState([]);
//   const [isShow, setIsShow] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [show, setShow] = useState(true);
//   const [done, setDone] = useState(false);
//   const [pdfFile, setPDFfile] = useState(null);
//   const [viewPdf, setViewPdf] = useState("");
//   const [open, setOpen] = React.useState(false);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [showPreview, setShowPreview] = useState(false);
//   const [showModal, setShowModal] = useState(false);

//   let data = JSON.parse(localStorage.getItem("user"));
//   let numDeclaration = useMemo(
//     () => [data.data.numero_declaration],
//     [data.data.numero_declaration]
//   );

//   const convertBase64 = (file) => {
//     return new Promise((resolve, reject) => {
//       const fileReader = new FileReader();
//       fileReader.readAsDataURL(file);
//       fileReader.onload = () => {
//         resolve(fileReader.result);
//       };
//       fileReader.onerror = (error) => {
//         reject(error);
//       };
//     });
//   };

//   const fileType = ["application/pdf"];
//   const PreviewComponent = ({ field }) => {
//     console.log("id", previewImages[field.id].files);
//     if (previewImages[field.id].files) {
//       if (
//         previewImages[field.id].files.type.startsWith("image/") ||
//         previewImages[field.id].files.type.startsWith("jpg/") ||
//         previewImages[field.id].files.type.startsWith("png/") ||
//         previewImages[field.id].files.type.startsWith("tiff/") ||
//         previewImages[field.id].files.type.startsWith("bmp/")
//       ) {
//         const imageUrl = URL.createObjectURL(previewImages[field.id].files);
//         setShowModal(true);
//         return (
//           <Modal2 show={showModal}>
//             <Modal2.Body>
//               {" "}
//               <img
//                 src={imageUrl}
//                 style={{ width: "900px", height: "400px" }}
//                 alt={`Preview ${field.id}`}
//               />
//             </Modal2.Body>
//             <Modal2.Footer>
//               <Button2
//                 variant="secondary"
//                 onClick={() => handleModalClose(field.id)}
//               >
//                 Fermer
//               </Button2>
//             </Modal2.Footer>
//           </Modal2>
//         );
//       } else if (
//         previewImages[field.id].files &&
//         fileType.includes(previewImages[field.id].files.type)
//       ) {
//         let reader = new FileReader();
//         reader.readAsDataURL(previewImages[field.id].files);
//         reader.onload = () => {
//           setPDFfile(reader.result);
//         };
//         setOpen(true);

//         return (
//           <div>
//             <Modal
//               open={open}
//               onClose={() => handleClose(field.id)}
//               aria-labelledby="modal-modal-title"
//               aria-describedby="modal-modal-description"
//             >
//               <Box sx={style}>
//                 <div
//                   style={{ display: "flex", justifyContent: "space-between" }}
//                 >
//                   <Typography
//                     id="modal-modal-title"
//                     variant="h6"
//                     component="h2"
//                   >
//                     Visualisation
//                   </Typography>
//                   <CloseIcon
//                     onClick={() => handleClose(field.id)}
//                     style={{ cursor: "pointer" }}
//                   />
//                 </div>
//                 <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
//                   {pdfFile && (
//                     <>
//                       <Viewer fileUrl={pdfFile} pugins={[newplugin]} />
//                     </>
//                   )}
//                   {!pdfFile && <>No PDF</>}
//                 </Worker>
//               </Box>
//             </Modal>
//           </div>
//         );
//       } else {
//         return <p>Preview not available for this file type.</p>;
//       }
//     } else {
//       return null;
//     }
//   };

//   const handleImageChange = async (event, index) => {
//     const { name, files, id } = event.target;
//     const newImageFields = [...imageFields];
//     if (name === "file") {
//       newImageFields[index][name] = await convertBase64(files[0]);
//       newImageFields[index][id] = files[0];
//     }
//     setPreviewImages(newImageFields);
//     setImageFields(newImageFields);
//   };
//   console.log("previewImages", previewImages);
//   console.log("imageFields", imageFields);

//   const handleTogglePreview = (id) => {
//     const newImageFields = [...imageFields];
//     newImageFields.forEach((field) => {
//       if (field.id === id) {
//         field.previewVisible = !field.previewVisible;
//       }
//     });
//     setImageFields(newImageFields);
//   };

//   const handleClose = (id) => {
//     setOpen(false);
//     setPDFfile(null);
//     const newImageFields = [...imageFields];
//     newImageFields.forEach((field) => {
//       if (field.id === id) {
//         field.previewVisible = !field.previewVisible;
//       }
//     });

//     setImageFields(newImageFields);
//   };

//   const handleModalClose = (id) => {
//     setShowModal(false);
//     const newImageFields = [...imageFields];
//     newImageFields.forEach((field) => {
//       if (field.id === id) {
//         field.previewVisible = !field.previewVisible;
//       }
//     });
//     setImageFields(newImageFields);
//   };

//   const handleAddField = () => {
//     setIsShow(true);
//     const newId = imageFields.length;
//     const newField = { id: newId, file: null, previewVisible: false };
//     setImageFields([...imageFields, newField]);
//     setPreviewImages([...previewImages, null]);
//   };

//   const handleDeleteField = (id) => {
//     const newImageFields = imageFields.filter((field) => field.id !== id);
//     const newPreviewImages = previewImages.filter(
//       (image, index) => index !== id
//     );
//     setImageFields(newImageFields);
//     setPreviewImages(newPreviewImages);
//   };
//   const handleSubmit = (e) => {
//     e.preventDefault();
// let pieces_beneficiaire = [];

// imageFields.forEach((each, index) => {
//   pieces_beneficiaire.push([
//     `${-1}`,
//     "from ajouter piece",
//     null,
//     "scanne",
//     `${each.file}`,
//   ]);
// });

// var data = JSON.stringify({
//   numero_declaration: `${numDeclaration}`,
//   pieces_assure: [],
//   pieces_beneficiaire: pieces_beneficiaire,
// });

// setLoading(true);
// var config = {
//   method: "post",
//   maxBodyLength: Infinity,
//   url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/add-documents`,
//   headers: {
//     "Content-Type": "application/json",
//   },
//   data: data,
// };

// axios(config)
//   .then((response) => {
//     setLoading(false);
//     toast.success("Féliciations! Fichier envoyer.", {
//       position: "top-center",
//       autoClose: 1000000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "dark",
//     });
//     setDone(true);
//     setShow(false);
//   })
//   .catch((error) => {
//     setLoading(false);
//     toast.error(
//       "Désolé! Le service que vous tentez de joindre ne répond pas. Veuillez réessayer plus tard.",
//       {
//         position: "top-center",
//         autoClose: 1000000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "dark",
//       }
//     );
//     console.log(error);
//   });
//   };
//   return (
//     <>
//       {loading ? (
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Loader />
//         </div>
//       ) : done ? (
//         <p
//           className="text-2xl font-bold font-mono p-8 text-gray-200"
//           style={{ backgroundColor: "rgb(0,0,0,0.7)" }}
//         >
//           Fichier en cours de Traitement...{" "}
//         </p>
//       ) : show ? (
//         <form onSubmit={handleSubmit}>
//           {isShow ? (
//             <>
//               <div>
//                 {imageFields.map((field, index) => {
//                   return (
//                     <div
//                       className="d-md-flex justify-round items-center transmettre"
//                       style={{
//                         justifyContent: "center",
//                         alignItems: "center",
//                         backgroundColor: "rgb(0,0,0,0.2)",
//                         margin: "8px",
//                       }}
//                       key={field.id}
//                     >
//                       <div className="flex align-items-center m-4">
//                         <button
//                           type="button"
//                           onClick={() => handleTogglePreview(field.id)}
//                           className="btn btn-success"
//                         >
//                           <GrView />
//                         </button>
//                       </div>
//                       <div className="flex w-full align-center">
//                         <input
//                           className="text-black bg-gray-200 p-2 m-2 w-100 rounded outline-none"
//                           name="file"
//                           type="file"
//                           id="files"
//                           style={{ cursor: "pointer" }}
//                           onChange={(event) =>
//                             handleImageChange(event, field.id)
//                           }
//                           required
//                         />
//                         {field.previewVisible && (
//                           <PreviewComponent field={field} />
//                         )}
//                         <button
//                           type="button"
//                           onClick={() => handleDeleteField(field.id)}
//                         >
//                           <IoMdTrash size={30} color="#e88080" />
//                         </button>
//                       </div>
//                     </div>
//                   );
//                 })}
//               </div>
//             </>
//           ) : null}
//           {/*
// <div className="flex align-items-center m-4">
//         <button
//           type="button"
//           onClick={handleTogglePreview}
//           className="btn btn-success"
//         >
//           <GrView />
//         </button>
//       </div>
//       <div className="flex w-full align-center">
//         <input
//           className="text-black bg-gray-200 p-2 m-2 w-100 rounded outline-none"
//           type="file"
//           onChange={handleFileSelect}
//           required
//         />
//         {previewVisible && selectedFile && (
//           <PreviewComponent field={selectedFile} />
//         )}
//       </div> */}

//           <div>
//             <div className="flex mb-2 cutom-box ml-4">
//               <div className="flex w-1/2 mx-2 custom-with ajouter-autre ">
//                 <button
//                   type="button"
//                   onClick={handleAddField}
//                   className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
//                 >
//                   <div className="flex">
//                     <AiFillPlusCircle size={25} className="mr-2" />
//                     Ajouter une pièce
//                   </div>
//                 </button>
//               </div>
//             </div>
//             {isShow ? (
//               <div className="flex items-center justify-center">
//                 <button
//                   className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
//                   type="submit"
//                 >
//                   <span className="flex">
//                     <IoIosSend size={20} className="mr-2" /> Transmettre
//                   </span>
//                 </button>
//               </div>
//             ) : null}
//           </div>
//         </form>
//       ) : null}
//     </>
//   );
// }

// export default ImageUpload;

import React, { useState, useContext, useMemo, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import global_variables from "../../controllers/globalVar.js";
import global_variables from "../../../controllers/globalVar.js";
import { IoIosSend, IoMdTrash } from "react-icons/io";
// import { AppContext } from "../../../context/ContextApi";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineFile,
  AiFillPlusCircle,
} from "react-icons/ai";
import Loader from "../../../loading/Loading.js";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { CgEye } from "react-icons/cg";
import { BsPlus } from "react-icons/bs";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Button2 from "react-bootstrap/Button";
import Modal2 from "react-bootstrap/Modal";
import listPiece from "../../../../data/list-pieces.json";
import complement from "../../../../data/complement.json";

const style = {
  margin: 12,
  height: 700,
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function MultiForm({ id }) {
  const newplugin = defaultLayoutPlugin();
  // const {
  //   individuel,
  //   individuelNextStep,
  //   individuelPreviousStep,
  //   setFileName,
  //   setFileName2,
  //   setFileName3,
  //   staticFields,
  //   setStaticFields,
  //   staticSelectedCheckboxes,
  //   setStaticSelectedCheckboxes,
  //   dynamicSelectedCheckboxes,
  //   setDynamicSelectedCheckboxes,
  //   newPieceDynamicBox,
  //   setNewPieceDynamicBox,
  //   newPieceDynamicFields,
  //   setNewPieceDynamicFields,
  // } = useContext(AppContext);
  const [imageFields, setImageFields] = useState([
    { id: 0, beneficiaire: "", file: null, previewVisible: false },
  ]);
  const [previewImages, setPreviewImages] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [done, setDone] = useState(false);
  const [pdfFile, setPDFfile] = useState(null);
  const [viewPdf, setViewPdf] = useState("");
  const [open, setOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [libelleList, setLibelleList] = useState([]);

  let data = JSON.parse(localStorage.getItem("user"));
  let numDeclaration = useMemo(() => [data.numero_declaration], [
    data.numero_declaration,
  ]);

  useEffect(() => {
    // var config = {
    //   method: "get",
    //   maxBodyLength: Infinity,
    //   url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/list-pieces`,
    //   headers: {},
    // };
    // axios(config)
    //   .then(function(response) {
      var response = listPiece;
        if (response.status === true) {
          setLibelleList(response.data);
        } else {
          setLibelleList([]);
        }
        // console.log("response", response.data.data);
        // console.log("status", response.data.status);
      // })
      // .catch(function(error) {
      //   toast.error(
      //     "Désolé! Le service que vous tentez de joindre ne répond pas. Veuillez réessayer plus tard.",
      //     {
      //       position: "top-center",
      //       autoClose: 10000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //       theme: "dark",
      //     }
      //   );
      //   console.log(error);
      // });
  }, []);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const fileType = ["application/pdf"];
  const PreviewComponent = ({ field }) => {
    // console.log("id", previewImages[field.id].files);
    if (previewImages[field.id].files) {
      if (
        previewImages[field.id].files.type.startsWith("image/") ||
        previewImages[field.id].files.type.startsWith("jpg/") ||
        previewImages[field.id].files.type.startsWith("png/") ||
        previewImages[field.id].files.type.startsWith("tiff/") ||
        previewImages[field.id].files.type.startsWith("bmp/")
      ) {
        const imageUrl = URL.createObjectURL(previewImages[field.id].files);
        setShowModal(true);
        return (
          <Modal2 show={showModal}>
            <Modal2.Body>
              {" "}
              <img
                src={imageUrl}
                style={{ width: "900px", height: "400px" }}
                alt={`Preview ${field.id}`}
              />
            </Modal2.Body>
            <Modal2.Footer>
              <Button2
                variant="secondary"
                onClick={() => handleModalClose(field.id)}
              >
                Fermer
              </Button2>
            </Modal2.Footer>
          </Modal2>
        );
      } else if (
        previewImages[field.id].files &&
        fileType.includes(previewImages[field.id].files.type)
      ) {
        let reader = new FileReader();
        reader.readAsDataURL(previewImages[field.id].files);
        reader.onload = () => {
          setPDFfile(reader.result);
        };
        setOpen(true);

        return (
          <div>
            <Modal
              open={open}
              onClose={() => handleClose(field.id)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Visualisation
                  </Typography>
                  <CloseIcon
                    onClick={() => handleClose(field.id)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                  {pdfFile && (
                    <>
                      <Viewer fileUrl={pdfFile} pugins={[newplugin]} />
                    </>
                  )}
                  {!pdfFile && <>No PDF</>}
                </Worker>
              </Box>
            </Modal>
          </div>
        );
      } else {
        return <p>Preview not available for this file type.</p>;
      }
    } else {
      return null;
    }
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const newInputs = [...imageFields];
    newInputs[index][name] = value;
    setImageFields(newInputs);
  };

  const handleImageChange = async (event, index) => {
    const { name, files, id } = event.target;
    const newImageFields = [...imageFields];
    if (name === "file") {
      newImageFields[index][name] = await convertBase64(files[0]);
      newImageFields[index][id] = files[0];
    }
    setPreviewImages(newImageFields);
    setImageFields(newImageFields);
  };
  // console.log("previewImages", previewImages);
  // console.log("imageFields", imageFields);

  const handleTogglePreview = (id) => {
    const newImageFields = [...imageFields];
    newImageFields.forEach((field) => {
      if (field.id === id) {
        field.previewVisible = !field.previewVisible;
      }
    });
    setImageFields(newImageFields);
  };

  const handleClose = (id) => {
    setOpen(false);
    setPDFfile(null);
    const newImageFields = [...imageFields];
    newImageFields.forEach((field) => {
      if (field.id === id) {
        field.previewVisible = !field.previewVisible;
      }
    });

    setImageFields(newImageFields);
  };

  const handleModalClose = (id) => {
    setShowModal(false);
    const newImageFields = [...imageFields];
    newImageFields.forEach((field) => {
      if (field.id === id) {
        field.previewVisible = !field.previewVisible;
      }
    });
    setImageFields(newImageFields);
  };

  const handleAddField = () => {
    setIsShow(true);
    const newId = imageFields.length;
    const newField = {
      id: newId,
      beneficiaire: "",
      file: null,
      previewVisible: false,
    };
    setImageFields([...imageFields, newField]);
    setPreviewImages([...previewImages, null]);
  };

  const handleDeleteField = (id) => {
    const newImageFields = imageFields.filter((field) => field.id !== id);
    const newPreviewImages = previewImages.filter(
      (image, index) => index !== id
    );
    setImageFields(newImageFields);
    setPreviewImages(newPreviewImages);
  };

  console.log("imageFields", imageFields);
  console.log("id of multiForm", id);
  const handleSubmit = (e) => {
    // e.preventDefault();
    // let piecess = [];
    // imageFields.forEach((each, index) => {
    //   piecess.push([
    //     `${id}`,
    //     null,
    //     `${each.beneficiaire}`,
    //     "scanne",
    //     `${each.file}`,
    //   ]);
    // });

    // var data = JSON.stringify({
    //   numero_declaration: `${numDeclaration}`,
    //   pieces_assure: [],
    //   pieces_beneficiaire: piecess,
    // });

    setLoading(true);
    // var config = {
    //   method: "post",
    //   maxBodyLength: Infinity,
    //   url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/add-documents`,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   data: data,
    // };

    // axios(config)
    //   .then((response) => {
      var response = complement;
        setLoading(false);
        toast.success("Féliciations! Fichier envoyer.", {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setDone(true);
        setShow(false);
        // Refresh the page
        // window.location.reload();
      // })
      // .catch((error) => {
      //   setLoading(false);
      //   toast.error(
      //     "Désolé! Le service que vous tentez de joindre ne répond pas. Veuillez réessayer plus tard.",
      //     {
      //       position: "top-center",
      //       autoClose: 10000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //       theme: "dark",
      //     }
      //   );
      //   console.log(error);
      // });
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      ) : done ? (
        <p
          className="text-2xl font-bold font-mono p-8 text-gray-200"
          style={{ backgroundColor: "rgb(0,0,0,0.7)" }}
        >
          Fichier en cours de Traitement...{" "}
        </p>
      ) : show ? (
        <form onSubmit={handleSubmit}>
          <>
            <div>
              {imageFields.map((field, index) => {
                return (
                  <div
                    className="d-md-flex justify-round items-center transmettre"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgb(0,0,0,0.2)",
                      margin: "8px",
                    }}
                    key={field.id}
                  >
                    <div className="flex w-full align-center">
                      <input style={{ minWidth: "200px" }}
                        className="text-black bg-gray-200 p-2 m-2 rounded outline-none"
                        type="text"
                        name="beneficiaire"
                        placeholder="Beneficiaire"
                        value={field.beneficiaire}
                        required
                        onChange={(event) => handleInputChange(event, field.id)}
                      />
                      {/* <select
                        className="w-1/2 text-black bg-gray-200 p-2 m-2 rounded outline-none"
                        name="dropdown"
                        value={field.dropdownValue}
                        onChange={(e) => handleInputChange(e, field.id)}
                      >
                        <option value="">
                          Selectionner le type de fichier
                        </option>
                        {libelleList.map((libelle, index) => {
                          return (
                            <option
                              key={index}
                              id={libelle.id}
                              value={libelle.libelle}
                            >
                              {libelle.libelle}
                            </option>
                          );
                        })}
                      </select> */}
                      <input
                        className="text-black bg-gray-200 p-1 m-2 w-1/2 rounded outline-none"
                        name="file"
                        type="file"
                        id="files"
                        style={{ cursor: "pointer", minWidth: "300px" }}
                        onChange={(event) => handleImageChange(event, field.id)}
                        required
                      />
                      {field.previewVisible && (
                        <PreviewComponent field={field} />
                      )}
                      <div className="flex align-items-center">
                        <button
                            type="button"
                            onClick={() => handleTogglePreview(field.id)}
                            className="btn mr-2 btn-success"
                        >
                          <CgEye size={20} />
                        </button>
                        <button
                            type="button"
                            onClick={() => handleDeleteField(field.id)}
                            className="btn mr-2 btn-danger"
                        >
                          <IoMdTrash size={20} />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>

          <div>
            <div className="flex mb-2 cutom-box ml-4">
              <div className="flex w-1/2 mx-2 custom-with ajouter-autre ">
                <button
                  onClick={handleAddField}
                  type="button"
                  className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-2 py-1 mx-1 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                >
                  <span className="relative flex justify-center items-center">
                    <BsPlus size={27} color="#fbbf24" className="mr-1" />{" "}
                    Ajouter Autre
                  </span>
                </button>

                <button
                    className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-2 py-1 mx-1 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                    type="submit"
                >
                <span className="flex">
                  <IoIosSend size={25} color="#fbbf24" className="mr-1" /> Transmettre
                </span>
                </button>
              </div>
            </div>
            {/*<div className="flex items-center justify-center">*/}
            {/*  <button*/}
            {/*    className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"*/}
            {/*    type="submit"*/}
            {/*  >*/}
            {/*    <span className="flex">*/}
            {/*      <IoIosSend size={20} className="mr-2" /> Transmettre*/}
            {/*    </span>*/}
            {/*  </button>*/}
            {/*</div>*/}
          </div>
        </form>
      ) : null}
    </>
  );
}

export default MultiForm;
