import React from "react";
import "./suivrehome.css";
import TableGrid from "./TableGrid.js";
import Chronogramme from "./Chronogramme.js";
import ChronogrammeMobile from "./chronogrammeMobile";

const SuivreHome = () => {
  return (
    <section
      style={{
        backgroundImage: `url(./img/backgrounds/suivre4.jpg)`,
        top: "center",
      }}
      id="hero"
      className="in-containerlog"
    >
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="fixed-element col-md-4 col-xl-3 d-none d-md-block">
            <Chronogramme />
          </div>
          <div className="d-block d-md-none">
            <ChronogrammeMobile />
          </div>
          <div className="scrollable-element col-md-9 col-xl-9 mt-8 px-md-5">
            <TableGrid />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuivreHome;
