import React, { useEffect, useMemo, useState, useContext } from "react";
import "./tablegrid.css";
import { Link, useNavigate } from "react-router-dom";
import { BsFillEnvelopeFill } from "react-icons/bs";
import {
  AiFillFileText,
  AiOutlineArrowLeft,
  AiOutlineLogin,
} from "react-icons/ai";
import { GiMoneyStack, GiReceiveMoney } from "react-icons/gi";
import { FaHistory } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import qs from "qs";
import { toast } from "react-toastify";
import { AppContext } from "../../../context/ContextApi";
import global_variables from "../../controllers/globalVar";
import AlertContent from "../../alertContent/AlertContent.js";
import Chronogramme from "./Chronogramme.js";
import rechercheParNum from "../../../data/rechercheParNum.json";

const TableGrid = () => {
  const {
    individuel,
    individuelNextStep,
    individuelPreviousStep,
    fileName,
    fileName2,
    fileName3,
    isCheck,
    selectedOption,
    setSelectedOption,
    selectedItem,
    setSelectedItem,
    declareAcceuil,
    setDeclareAcceuil,
    successful,
    setSuccessful,
    message,
    setMessage,
    showAlert,
    alert,
    researchResult,
    setSearchResult,
  } = useContext(AppContext);
  //testing data
  let dataTest = {
    data: {
      date_declaration: "",
      numero_declaration: "",
      declarant: "",
      polices: [
        {
          numero_police: "",
          statut_sinistre: "",
          statut_police: "",
        },
        {
          numero_police: "",
          statut_sinistre: "",
          statut_police: "",
        },
        {
          numero_police: "",
          statut_sinistre: "",
          statut_police: "",
        },
      ],
    },
  };
  const [courier, setCourier] = useState([]);
  const [loading, setLoading] = useState(false);
  //getting data from localstorage
  let data = JSON.parse(localStorage.getItem("user")) || dataTest;

  console.log("data from tableGrid", data);
  let numDeclaration = useMemo(() => [data.numero_declaration], [
    data.numero_declaration,
  ]);
  const navigate = useNavigate();

  const date = new Date(data.date_declaration);
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const formattedDate = date.toLocaleDateString("fr-FR", options);

  useEffect(() => {
    var data = qs.stringify({
      numero_declaration: `${numDeclaration}`,
    });
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${
        global_variables().SINISTRE_CARE_WEBSERVICE
      }/list-couriers-declaration`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setCourier(response.data.data.nouveaux_courriers);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [numDeclaration]);

  const handleCourierSinistre = async (id) => {
    setLoading(true);
    if (!id) return null;
    let key = id || "c92728a5-d0d0-4712-8f53-87c6aa2d2c68";
    var data = qs.stringify({
      token: `${key}`,
    });
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${
        global_variables().SINISTRE_CARE_WEBSERVICE
      }/list-couriers-sinistre`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response.data.status === false && response.data.data.length === 0) {
          setLoading(false);
          toast.error(`${response.data.error}`, {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } else {
          localStorage.setItem("courrierSini", JSON.stringify(response.data));
          navigate("/couriersini");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          "Désolé! Le service que vous tentez de joindre ne répond pas veuillez réessayer plus tard..",
          {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        console.log(error);
      });
  };
  if (loading) return <AlertContent />;

  return (
    <>
      <div className="card-body py-3 rounded-lg mt-sm-0 mt-md-5 mt-lg-5">
        <h4 className="text-4xl p-2 text-white responsive-alignment-header font-mono leading-tight text-center">
          INFORMATIONS DE LA DECLARATION
        </h4>
        <div className="row flex justify-around responsive-alignment-container">
          <div className="col-sm-12 col-md-12 col-lg-6  flex justify-center  items-center  px-2 ">
            <h4 className="custom-with-label  responsive-alignment  text-xl font-serif font-medium text-gray-300 md:text-xl dark:text-gray-400">
              DATE DE DECLARATION :
            </h4>
            <dd className="text-xl text-white  responsive-alignment-element custom-with-label font-mono mx-4 font-semibold">
              {formattedDate}
            </dd>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6  flex justify-center  items-center  px-2 ">
            <h4 className="custom-with-label  responsive-alignment  text-xl font-serif font-medium text-gray-300 md:text-xl dark:text-gray-400">
              NUMERO DE DECLARATION :
            </h4>
            <dd className="text-xl text-white  responsive-alignment-element custom-with-label font-mono mx-4 font-semibold">
              {data.numero_declaration}
            </dd>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6  flex justify-center  items-center  px-2 ">
            <h4 className="custom-with-label responsive-alignment  text-xl font-serif font-medium text-gray-300 md:text-xl dark:text-gray-400">
              DECLARANT :
            </h4>
            <dd className="text-xl text-white  responsive-alignment-element custom-with-label font-mono mx-4 font-semibold">
              {data.declarant}
            </dd>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6  flex justify-center  items-center  px-2 ">
            <h4 className="custom-with-label responsive-alignment text-xl font-serif font-medium text-gray-300 md:text-xl dark:text-gray-400">
              CONTACTS DU DECLARANT :
            </h4>
            <dd className=" text-white text-xl  responsive-alignment-element custom-with-label font-mono mx-4 font-semibold">
              {data.numero_declarant}
            </dd>
          </div>
          <div className="col-12 py-3 flex justify-center text-center">
            <Link style={{ textDecoration: "none" }} to="/piece">
              <button
                type="button"
                className="relative flex text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 rounded-lg text-xl mx-1 px-4 py-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              >
                <AiFillFileText className="m-1" />
                <div className="d-none d-md-block">Pièces</div>
              </button>
            </Link>
            {/* <Link style={{ textDecoration: 'none' }} to='/courierdecla' type="button" className="flex text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 rounded-lg text-xl mx-1 px-4 py-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
                        <BsFillEnvelopeFill className='m-1' />
                        {courier > 0 ? <span className="relative inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-3 -left-11 dark:border-gray-900">{courier}</span> : null}
                        <div style={{marginLeft: (courier > 0) ? '-20px' : ''}}>
                            <span className="d-none d-md-block">Courriers de déclaration</span>
                        </div>
                    </Link> */}
            <Link
              style={{ textDecoration: "none", cursor: "pointer" }}
              to="/history"
            >
              <button
                type="button"
                className="relative flex text-gray-900 bg-success border border-success text-dark focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 rounded-lg text-xl mx-1 px-4 py-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              >
                <FaHistory className="m-1" />
                <div className="d-none d-md-block">Historique</div>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="row my-3 relative">
        <div className="col-12 table-responsive">
          <table className="table table-sm table-nowrap min-w-full leading-normal">
            <thead>
              <tr>
                <th className="p-3 text-left text-gray-600 uppercase">
                  N° Police
                </th>
                <th className="p-3 text-left text-gray-600 uppercase">
                  Libellé produit
                </th>
                {/* <th className="p-3 text-left text-gray-600 uppercase">N° Sinistre</th> */}
                {/*<th className="p-3 text-left text-gray-600 uppercase">Garantie</th>*/}
                <th className="p-3 text-left text-gray-600 uppercase">
                  Statut
                </th>
                <th className="p-3 text-left text-gray-600 uppercase">
                  Règlement
                </th>
              </tr>
            </thead>
            <tbody className="card-body tbody-mobile text-left">
              {data.polices.map((police, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {police.numero_police
                        ? police.numero_police
                        : "Pas de numéro de police disponible"}
                    </td>
                    <td>
                      {police.libelle_produit
                        ? police.libelle_produit
                        : "Pas de libellé disponible"}
                    </td>
                    {/* <td>{police.numero_sinistre}</td> */}
                    {/*<td>{police.garantie}</td>*/}
                    <td className="text-nowrap">
                      <label
                        className={`${
                          police.statut_sinistre_id === 5
                            ? "bg-red-400 "
                            : police.statut_sinistre_id === 7
                            ? "bg-red-400 "
                            : police.statut_sinistre_id === 6
                            ? "bg-green-500"
                            : police.statut_sinistre_id === 8
                            ? "bg-green-500"
                            : "bg-yellow-500"
                        } text-black px-3 py-1 rounded-full dark:bg-yellow-900 dark:text-yellow-300`}
                      >
                        {police.statut_sinistre
                          ? police.statut_sinistre
                          : "Aucun statut"}
                      </label>
                    </td>
                    <td>
                      {(police.statut_sinistre_id === 6 ||
                        police.statut_sinistre_id === 8) &&
                      police.reglement.length !== 0 ? (
                        <>
                          {/* Button trigger modal */}
                          <button
                            type="button"
                            className="relative text-white bg-green-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 rounded-lg px-3 py-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <span className="flex justify-center items-center">
                              <GiMoneyStack size={20} className="mr-1" />{" "}
                              Afficher
                            </span>
                          </button>
                          {/* Modal */}
                          <div
                            className="modal fade"
                            id="exampleModal"
                            tabIndex={-1}
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h1
                                    className="modal-title fs-5 text-secondary"
                                    id="exampleModalLabel"
                                  >
                                    INFOS PAIEMENT -{" "}
                                    {police.reglement.description}
                                  </h1>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  />
                                </div>
                                <dl className="max-w-md px-4 text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                                  <div className="flex flex-col">
                                    <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                                      BENEFICIAIRE
                                    </dt>
                                    <dd className="text-lg bg-indigo-100 text-indigo-800  font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-indigo-900 dark:text-indigo-300 font-semibold">
                                      {police.reglement.beneficiaire}
                                    </dd>
                                  </div>
                                  <div className="flex flex-col">
                                    <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                                      MOTIF DE PAIEMENT
                                    </dt>
                                    <dd className="text-lg bg-indigo-100 text-indigo-800 font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-indigo-900 dark:text-indigo-300 font-semibold">
                                      {police.reglement.motif}
                                    </dd>
                                  </div>
                                  <div className="flex flex-col">
                                    <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                                      MONTANT DU PAIEMENT
                                    </dt>
                                    <dd className="text-lg bg-indigo-100 text-indigo-800 font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-indigo-900 dark:text-indigo-300 font-semibold">
                                      {police.reglement.montant_reglement} F.CFA
                                    </dd>
                                  </div>
                                  <div className="flex flex-col">
                                    <dt className="mb-1 text-gray-600 md:text-lg dark:text-gray-400">
                                      MODE DE PAIEMENT
                                    </dt>
                                    <dd className="text-lg bg-indigo-100 text-indigo-800 font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-indigo-900 dark:text-indigo-300 font-semibold">
                                      {police.reglement.mode_reglement}
                                    </dd>
                                  </div>
                                  <div className="flex flex-col">
                                    <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                                      STATUT DU PAIEMENT
                                    </dt>
                                    <dd className="text-lg bg-indigo-100 text-indigo-800 font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-indigo-900 dark:text-indigo-300 font-semibold">
                                      {police.reglement.statut_reglement}
                                    </dd>
                                  </div>
                                  {police.reglement.statut_reglement ===
                                    "Disponible" && (
                                    <div className="flex flex-col">
                                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                                        DATE DISPONIBILITE DU PAIEMENT
                                      </dt>
                                      <dd className="text-lg bg-indigo-100 text-indigo-800 font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-indigo-900 dark:text-indigo-300 font-semibold">
                                        {police.reglement.date_reglement}
                                      </dd>
                                    </div>
                                  )}
                                  {police.reglement.statut_reglement ===
                                    "Effectué" && (
                                    <div className="flex flex-col">
                                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                                        DATE RETRAIT DU PAIEMENT
                                      </dt>
                                      <dd className="text-lg bg-indigo-100 text-indigo-800 font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-indigo-900 dark:text-indigo-300 font-semibold">
                                        {police.reglement.date_retrait}
                                      </dd>
                                    </div>
                                  )}
                                </dl>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Fermer
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        "Pas de règlement disponible"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="declare-btn flex justify-center items-center">
        <Link
          to="/login"
          type="button"
          className="return-btn relative flex recherche-btn justify-center items-center py-2 px-5 text-2xl font-medium  rounded"
          style={{ textDecoration: "none" }}
        >
          <AiOutlineArrowLeft className="mr-2" /> Retour
        </Link>
      </div>
    </>
  );
};

export default TableGrid;
