import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../context/ContextApi";
import { Formik, Form } from "formik";
import "./individuel.css";
import { IoMdTrash } from "react-icons/io";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineFile,
  AiFillPlusCircle,
} from "react-icons/ai";
import axios from "axios";
import global_variables from "../../controllers/globalVar.js";
import { ToastContainer, toast } from "react-toastify";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { GrView } from "react-icons/gr";
import { CgEye } from "react-icons/cg";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Button2 from "react-bootstrap/Button";
import Modal2 from "react-bootstrap/Modal";

const style = {
  margin: 12,
  height: 700,
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Beneficiaires = () => {
  const newplugin = defaultLayoutPlugin();
  const {
    individuel,
    individuelNextStep,
    individuelPreviousStep,
    setFileName,
    setFileName2,
    setFileName3,
    staticFields,
    setStaticFields,
    dynamicFields,
    setDynamicFields,
    staticSelectedCheckboxes,
    setStaticSelectedCheckboxes,
    dynamicSelectedCheckboxes,
    setDynamicSelectedCheckboxes,
    staticBox,
    setStaticBox,
    dynamicBox,
    setDynamicBox,
  } = useContext(AppContext);
  const [message, setMessate] = useState("beginning");
  const [preview, setPreview] = useState({});
  const [customId, setCustomId] = useState(0);
  const [pdfFile, setPDFfile] = useState(null);
  const [viewPdf, setViewPdf] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [imageFields, setImageFields] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [pdfFile2, setPDFfile2] = useState(null);
  const [viewPdf2, setViewPdf2] = useState(null);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose3 = () => setOpen2(false);
  const [showModal2, setShowModal2] = useState(false);
  const [libelleList, setLibelleList] = useState([]);

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedFile(null);
  };
  // const handleShowModal = () => setShowModal(true);
  // const [selectedImage, setSelectedImage] = useState(null);

  // useEffect(() => {
  //   var config = {
  //     method: "get",
  //     maxBodyLength: Infinity,
  //     url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/list-pieces`,
  //     headers: {},
  //   };
  //   axios(config)
  //     .then(function(response) {
  //       if (response.data.status === true) {
  //         setLibelleList(response.data.data);
  //       } else {
  //         setLibelleList([]);
  //       }
  // console.log("response", response.data.data);
  // console.log("status", response.data.status);
  // })
  // .catch(function(error) {
  // toast.error(
  //   "Désolé! Le service que vous tentez de joindre ne répond pas. Veuillez réessayer plus tard.",
  //   {
  //     position: "top-center",
  //     autoClose: 10000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "dark",
  //   }
  // );
  //       console.log(error);
  //     });
  // }, []);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handlePreview = (e) => {
    e.preventDefault();
    if (pdfFile !== null) {
      setViewPdf(pdfFile);
      setOpen(true);
    } else {
      setViewPdf(null);
    }
    setShowPreview(true);
  };
  const fileType = ["application/pdf"];
  const PreviewComponent = () => {
    if (selectedFile) {
      // Handle the file type and display accordingly
      //   JPG, PNG, TIFF, BMP
      if (
        selectedFile.type.startsWith("image/") ||
        selectedFile.type.startsWith("jpg/") ||
        selectedFile.type.startsWith("png/") ||
        selectedFile.type.startsWith("tiff/") ||
        selectedFile.type.startsWith("bmp/")
      ) {
        const imageUrl = URL.createObjectURL(selectedFile);
        setShowModal(true);
        return (
          // <div className="mb-4 mt-2">
          //   <img src={imageUrl} alt="Preview" />
          // </div>
          //   <div>
          //   <input type="file" onChange={handleImageChange} />
          //   <button onClick={handleUpload}>Upload</button>
          // </div>
          <>
            {/* //   <Button2 variant="primary" onClick={handleShowModal}>
          //     Launch demo modal
          //   </Button2> */}

            <Modal2 show={showModal}>
              <Modal2.Body>
                {" "}
                <img src={imageUrl} alt="Preview" />
              </Modal2.Body>
              <Modal2.Footer>
                <Button2 variant="secondary" onClick={handleModalClose}>
                  Close
                </Button2>
              </Modal2.Footer>
            </Modal2>
          </>
          // </div>
        );
      } else if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = () => {
          setPDFfile(reader.result);
        };

        return (
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Visualisation
                  </Typography>
                  <CloseIcon
                    onClick={handleClose}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                  {viewPdf && (
                    <>
                      <Viewer fileUrl={viewPdf} pugins={[newplugin]} />
                    </>
                  )}
                  {!viewPdf && <>No PDF</>}
                </Worker>
              </Box>
            </Modal>
          </div>
        );
      } else {
        return <p>Preview not available for this file type.</p>;
      }
    } else {
      return null; // No file selected, don't render the preview component
    }
    setShowModal(false);
  };

  const staticCheckboxes = [
    { id: 1, label: "Copie" },
    { id: 2, label: "Original" },
    { id: 3, label: "Scanne" },
  ];

  const handleCheckboxstatic = (event) => {
    const checkboxId = parseInt(event.target.id);
    const isChecked = event.target.checked;
    const checkboxlabel = event.target.value;

    if (isChecked) {
      setStaticSelectedCheckboxes([
        ...staticSelectedCheckboxes,
        checkboxId,
        checkboxlabel,
        isChecked,
      ]);
    } else {
      setStaticSelectedCheckboxes(
        staticSelectedCheckboxes.filter((id) => id !== checkboxId)
      );
    }
  };
  const handleCheckboxdynamic = (event) => {
    const checkboxId = parseInt(event.target.id);
    const isChecked = event.target.checked;
    const checkboxlabel = event.target.value;

    if (isChecked) {
      setDynamicSelectedCheckboxes([
        ...dynamicSelectedCheckboxes,
        checkboxId,
        checkboxlabel,
        isChecked,
      ]);
    } else {
      setDynamicSelectedCheckboxes(
        dynamicSelectedCheckboxes.filter((id) => id !== checkboxId)
      );
    }
  };

  // state to show dynamic inputs
  const [isShow, setIsShow] = useState(false);

  // handler function to update static checkbox state
  const handleStaticFile = async (event) => {
    let eventFile = event.target.files[0];

    // console.log("eventFile", eventFile);
    setSelectedFile(eventFile);
    let convertFile = await convertBase64(event.target.files[0]);
    setStaticFields({ ...staticFields, file: convertFile });
  };

  // handler function to update dynamically added input field state
  const handleDynamicFields = async (e, index) => {
    const { name, value, files, id } = e.target;
    const list = [...dynamicFields];
    if (name === "file") {
      list[index][name] = await convertBase64(files[0]);
      list[index][id] = files[0];
    } else if (e.target.options[e.target.selectedIndex].id) {
      list[index].dropdownValue = e.target.value;
      list[index].newFileId = parseInt(
        e.target.options[e.target.selectedIndex].id,
        10
      );
      // list[index][name] = value;
    }
    setDynamicFields(list);
    setPreviewImages(list);
  };
  // console.log("previewImages", previewImages);
  // console.log("DynamicFields", dynamicFields);

  // handler function to add a new input field
  const handleAddField = () => {
    setIsShow(true);
    const newId = dynamicFields.length;
    const newField = {
      id: newId,
      file: null,
      previewVisible: false,
      text: "",
      dropdownValue: "",
    };
    setDynamicFields([...dynamicFields, newField]);
    setPreviewImages([...previewImages, null]);

    // setCustomId(customId + 1);
    // setDynamicFields([
    //   ...dynamicFields,
    //   {
    //     dynamicCheckboxes: [
    //       {
    //         id: `originale-${customId}`,
    //         name: `piece-${customId}`,
    //         value: "originale",
    //       },
    //       {
    //         id: `copie-${customId}`,
    //         name: `piece-${customId}`,
    //         value: "copie",
    //       },
    //       {
    //         id: `scannee-${customId}`,
    //         name: `piece-${customId}`,
    //         value: "scannee",
    //       },
    //     ],
    //     text: "",
    //     file: null,
    //   },
    // ]);
  };

  // handler function to remove an input field
  const handleRemoveField = (id) => {
    const newImageFields = dynamicFields.filter((field) => field.id !== id);
    const newPreviewImages = previewImages.filter(
      (image, index) => index !== id
    );
    setDynamicFields(newImageFields);
    setPreviewImages(newPreviewImages);
    // setDynamicFields((prevState) => {
    //   const newInputFields = [...prevState];
    //   newInputFields.splice(index, 1);
    //   return newInputFields;
    // });
  };

  const handleSubmit = (values) => {
    individuelNextStep(values);
  };
  const handleBack = (values) => {
    individuelPreviousStep(values);
  };

  //************new update for dynamic inputs files */
  const fileType2 = ["application/pdf"];
  const PreviewComponent2 = ({ dynamicField }) => {
    // console.log("id", previewImages[dynamicField.id].files);
    // console.log("dynamicFields from previewComponent2", previewImages);
    if (previewImages[dynamicField.id].files) {
      if (
        previewImages[dynamicField.id].files.type.startsWith("image/") ||
        previewImages[dynamicField.id].files.type.startsWith("jpg/") ||
        previewImages[dynamicField.id].files.type.startsWith("png/") ||
        previewImages[dynamicField.id].files.type.startsWith("tiff/") ||
        previewImages[dynamicField.id].files.type.startsWith("bmp/")
      ) {
        const imageUrl = URL.createObjectURL(
          previewImages[dynamicField.id].files
        );
        setShowModal2(true);
        return (
          <Modal2 show={showModal2}>
            <Modal2.Body>
              {" "}
              <img
                src={imageUrl}
                style={{ width: "900px", height: "400px" }}
                alt={`Preview ${dynamicField.id}`}
              />
            </Modal2.Body>
            <Modal2.Footer>
              <Button2
                variant="secondary"
                onClick={() => handleModalClose2(dynamicField.id)}
              >
                Fermer
              </Button2>
            </Modal2.Footer>
          </Modal2>
        );
      } else if (
        previewImages[dynamicField.id].files &&
        fileType2.includes(previewImages[dynamicField.id].files.type)
      ) {
        let reader = new FileReader();
        reader.readAsDataURL(previewImages[dynamicField.id].files);
        reader.onload = () => {
          setPDFfile2(reader.result);
        };
        setOpen2(true);

        return (
          <div>
            <Modal
              open={open2}
              onClose={() => handleClose2(dynamicField.id)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Visualisation
                  </Typography>
                  <CloseIcon
                    onClick={() => handleClose2(dynamicField.id)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                  {pdfFile2 && (
                    <>
                      <Viewer fileUrl={pdfFile2} pugins={[newplugin]} />
                    </>
                  )}
                  {!pdfFile2 && <>No PDF</>}
                </Worker>
              </Box>
            </Modal>
          </div>
        );
      } else {
        return <p>Preview not available for this file type.</p>;
      }
    } else {
      return null;
    }
  };
  const handleTogglePreview = (id) => {
    const newImageFields = [...dynamicFields];
    newImageFields.forEach((field) => {
      if (field.id === id) {
        field.previewVisible = !field.previewVisible;
      }
    });
    setDynamicFields(newImageFields);
  };

  const handleClose2 = (id) => {
    setOpen2(false);
    setPDFfile2(null);
    const newImageFields = [...dynamicFields];
    newImageFields.forEach((field) => {
      if (field.id === id) {
        field.previewVisible = !field.previewVisible;
      }
    });

    setDynamicFields(newImageFields);
  };

  const handleModalClose2 = (id) => {
    setShowModal2(false);
    const newImageFields = [...dynamicFields];
    newImageFields.forEach((field) => {
      if (field.id === id) {
        field.previewVisible = !field.previewVisible;
      }
    });
    setDynamicFields(newImageFields);
  };

  return (
    <Formik initialValues={individuel} onSubmit={handleSubmit}>
      {({ errors, touched, setFieldValue, values }) => (
        <Form>
          <h3 className="section-title section-title-mobile">
            JOINDRE DES DOCUMENTS
          </h3>
          <div className="flex-col flex-col-mobile flex-col-mobile-documen">
            {/*<div>*/}
            <div className="row flex cutom-box mb-3">
              <label
                className="col-md-5 custom-with custom-with-label text-xl leading-3"
                htmlFor="benePrenom"
              >
                <div className="flex justify-center items-center my-1">
                  <AiOutlineFile size={30} /> Acte de décès{" "}
                  <strong style={{ color: "#cc992d" }}>*</strong>
                </div>
              </label>
              {/* <div className="w-1/2 mx-2 custom-with">
                                    <input id="originale" name="fichier_nature" onChange={(e) => {
                                        setStaticBox(e.target.id)
                                    }} type="radio" className="btn-check" autoComplete="off" />
                                    <label className="btn btn-outline-light m-2" htmlFor="originale">Originale</label>
                                    <input id="copie" name="fichier_nature" onChange={(e) => {
                                        setStaticBox(e.target.id)
                                    }} type="radio" className="btn-check" autoComplete="off" />
                                    <label className="btn btn-outline-light m-2" htmlFor="copie">Copie</label>
                                    <input id="scannee" name="fichier_nature" onChange={(e) => {
                                        setStaticBox(e.target.id)
                                    }} type="radio" className="btn-check" autoComplete="off" />
                                    <label className="btn btn-outline-light m-2" htmlFor="scannee">Scannee</label>
                                </div> */}
              <div className="col-md-7 flex custom-with my-1">
                <input
                  className="text-black bg-gray-200 p-1 w-100 rounded outline-none"
                  type="file"
                  name="file"
                  onChange={handleStaticFile}
                  required
                />
                <button
                  onClick={handlePreview}
                  className="btn btn-success mx-1"
                >
                  <CgEye />
                </button>

                {showPreview && <PreviewComponent />}
              </div>
            </div>
            {isShow ? (
              <>
                {dynamicFields.map((dynamicField, index) => {
                  return (
                    <div className="row flex cutom-box mb-3" key={index}>
                      <div className="col-md-5 custom-with my-1">
                        {/* <input
                            className="text-black bg-gray-200 p-2 m-2 rounded outline-none"
                            placeholder="Libelle de la pièce"
                            type="text"
                            name="text"
                            value={dynamicField.text}
                            onChange={(e) =>
                              handleDynamicFields(e, dynamicField.id)
                            }
                            required
                          /> */}
                        <select
                          className="text-black bg-gray-200 p-2 rounded outline-none w-100"
                          name="dropdown"
                          value={dynamicField.dropdownValue}
                          onChange={(e) =>
                            handleDynamicFields(e, dynamicField.id)
                          }
                        >
                          <option value="">
                            Selectionner le type de fichier
                          </option>
                          {libelleList.map((libelle, index) => {
                            return (
                              <option
                                key={index}
                                id={libelle.id}
                                value={libelle.libelle}
                              >
                                {libelle.libelle}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {/* <div className="flex w-1/2 mx-2 custom-with">
                                                {dynamicField.dynamicCheckboxes.map((checkbox, index) => (
                                                    <div className="custom-with">
                                                        <input key={index} id={checkbox.id} value={checkbox.value} name={checkbox.name} onChange={(e) =>
                                                        {
                                                            setDynamicBox([...dynamicBox, e.target.value])
                                                        }} type="radio" className="btn-check" autoComplete="off" />
                                                        <label className="btn btn-outline-light m-2" htmlFor={checkbox.id}>{checkbox.value}</label>
                                                    </div>
                                                ))}
                                            </div> */}

                      <div className="col-md-7 flex custom-with my-1">
                        <input
                          className="text-black bg-gray-200 p-1 w-100 rounded outline-none"
                          type="file"
                          name="file"
                          id="files"
                          onChange={(e) =>
                            handleDynamicFields(e, dynamicField.id)
                          }
                          required
                        />

                        {dynamicField.previewVisible && (
                          <PreviewComponent2 dynamicField={dynamicField} />
                        )}
                        <button
                          type="button"
                          onClick={() => handleTogglePreview(dynamicField.id)}
                          className="btn mx-1 btn-success"
                        >
                          <CgEye />
                        </button>
                        <button
                          type="button"
                          onClick={() => handleRemoveField(dynamicField.id)}
                          className="btn btn-danger"
                        >
                          <IoMdTrash />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : null}
            {/*</div>*/}
            <div className="flex my-3 cutom-box">
              <div className="flex w-1/2 mx-2 custom-with ajouter-autre">
                <button
                  onClick={handleAddField}
                  className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                >
                  <div className="flex">
                    <AiFillPlusCircle size={25} className="mr-2" /> Ajouter
                    autre
                  </div>
                </button>
              </div>
            </div>
            <div className="flex justify-between align-center text-center btn-container souscripteur-btn-mobile">
              <div className="w-1/2 mx-2 flex justify-center">
                <button
                  onClick={() => handleBack(values)}
                  type="button"
                  className="return-btn flex recherche-btn justify-center items-center py-2 px-3 text-2xl font-medium  rounded"
                >
                  <AiOutlineArrowLeft className="mr-2" /> Précédent
                </button>
              </div>
              <div className="w-1/2 mx-2 flex justify-center">
                <button
                  type="submit"
                  className="flex recherche-btn justify-center items-center py-2 px-3 text-2xl font-medium text-white  rounded border border-blue-700 focus:ring-4 focus:outline-none focus:bg-amber-400 hover:bg-amber-400 focus:bg-amber-400"
                >
                  Suivant <AiOutlineArrowRight className="ml-2" />
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Beneficiaires;
