import React, { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../../../context/ContextApi";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import "./individuel.css";
import * as Yup from "yup";
import Checkbox from "./checkbox.js";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
import { InputTags } from "react-bootstrap-tagsinput";
import "react-bootstrap-tagsinput/dist/index.css";

const validationSchema = Yup.object().shape({
  sousContactTel: Yup.string().required("Ce champ est requis"),
  sousAdressePostale: Yup.string(),
  sousNom: Yup.string().required("Ce champ est requis"),
  sousPrenom: Yup.string().required("Ce champ est requis"),
  sousDateNaissance: Yup.string(),
});

const Souscripteur = () => {
  const {
    individuel,
    individuelNextStep,
    setSelectedOption,
    setSelectedItem,
    isCheck,
    setIsCheck,
    showErrorMessage,
    error,
    numPolices,
    setNumPolices,
    isChecked,
    setIsChecked,
    handleCheckboxChange,
    setIndividuel,
    handleEmpty2,
    setUpdateContextStoreData,
    userCheckedData,
    searchResults,
  } = useContext(AppContext);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [contrats, setContrats] = useState([]);
  const [message, setMessage] = useState("");
  const [hasReloaded, setHasReloaded] = useState(false);
  const hasMounted = useRef(false);

  // const handleCheckboxChange = (event) => {
  //     console.log('checkbox',event.target.checked);
  //     setIsChecked(event.target.checked);
  //     console.log('ischecked',isChecked);
  // };

  //getting data from localstorage
  let data = JSON.parse(localStorage.getItem("data"));
  let response = data?.data.client.contrats;

  //tout cocher function
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(response?.map((li) => li.NUMERO_POLICE));
    setContrats(response?.map((li) => li.LIBELLE_PRODUIT));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  useEffect(() => {
    // Check if the component is mounting for the first time
    // if (!hasMounted.current) {
    //   // Set a timeout to reload the page after 2 seconds
    //   const reloadTimeout = setTimeout(() => {
    //     window.location.reload();
    //   }, 2000);
    //   // Clear the timeout if the component unmounts before the 2 seconds
    //   return () => {
    //     clearTimeout(reloadTimeout);
    //     hasMounted.current = true; // Mark as mounted to prevent further executions
    //   };
    // }
  }, []);

  //clicker par item function
  const handleClick = (e) => {
    const { id, checked, name } = e.target;
    let dataId = e.target.getAttribute("dataId");
    let newIsCheckList = isCheck;

    if (!isCheck.includes(Number(dataId))) {
      newIsCheckList = [...newIsCheckList, Number(dataId)];
      setIsCheck(newIsCheckList);
    } else {
      newIsCheckList = newIsCheckList.filter((item) => item !== Number(dataId));
      // console.log("isCheck", newIsCheckList);
      setIsCheck(newIsCheckList);
    }
    setIsCheckAll(newIsCheckList.length === response.length);
    // console.log(newIsCheckList.length, response.length);
  };

  const catalog = response?.map(({ NUMERO_POLICE, index }) => {
    return (
      <>
        <Checkbox
          key={`${index}-police`}
          type="checkbox"
          name="police"
          dataId={NUMERO_POLICE}
          id={NUMERO_POLICE}
          handleClick={handleClick}
          isChecked={isCheck.includes(NUMERO_POLICE)}
        />
        <label
          className="btn btn-outline-light m-2"
          htmlFor={`${NUMERO_POLICE}`}
        >
          {" "}
          {NUMERO_POLICE}
        </label>
      </>
    );
  });

  const anotherCatalog = response?.map(
    ({ NUMERO_POLICE, LIBELLE_PRODUIT, index }) => {
      return (
        <>
          <Checkbox
            key={`${index}-contrat`}
            type="checkbox"
            name="contrat"
            dataId={NUMERO_POLICE}
            id={LIBELLE_PRODUIT}
            handleClick={handleClick}
            isChecked={isCheck.includes(NUMERO_POLICE)}
          />
          <label
            className="btn btn-outline-light m-2"
            htmlFor={`${LIBELLE_PRODUIT}`}
          >
            {" "}
            {LIBELLE_PRODUIT}
          </label>
        </>
      );
    }
  );

  //navigation initiation
  const navigate = useNavigate();
  // console.log("searchResults", searchResults);
  const handleSubmit = (values) => {
    // console.log("values", values);
    // if (isCheck.length === 0)
    // {
    //     showErrorMessage('Ce champ est requis')
    // } else
    // {
    //     individuelNextStep(values)
    // }
    // console.log("checkedResult", userCheckedData);
    // setIndividuel({
    //   ...individuel,
    //   assure: userCheckedData.name + " " + userCheckedData.prenoms,
    //   defDateNaissance: userCheckedData.dob,
    // });
    // values.assure =
    //   searchResults.nom_client + " " + searchResults.prenoms_client;
    // values.defDateNaissance = userCheckedData.dob;
    // console.log("user info from souscripteur", values.sousNom, values.sousPrenom,searchResults);
    individuelNextStep(values);
  };
  const handleReturn = (e) => {
    e.preventDefault();
    localStorage.clear();
    setIsChecked(false);
    handleEmpty2();
    setUpdateContextStoreData(false);
    // setSelectedItem(true)
    // setSelectedOption('1')
    navigate("/search");
  };
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={individuel}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, values }) => (
        <Form>
          <h3 className="section-title section-title-mobile">
            INFORMATIONS SUR LE CLIENT
          </h3>
          <div className="row flex cutom-box">
            <div className="col-md-4 custom-with">
              <label
                className="block text-grey-darker text-xl leading-3 mb-2"
                htmlFor="sousNom"
              >
                NOM <strong style={{ color: "#cc992d" }}>*</strong>
              </label>
              <Field
                className="appearance-none border custom-with-label rounded text-xl font-mono w-full p-2 text-grey-darker"
                name="sousNom"
                type="text"
                placeholder="NOM"
                // readOnly={true}
              />
              <p className="text-red-600 dark:text-red-500">
                {errors.sousNom && touched.sousNom ? errors.sousNom : null}
              </p>
            </div>
            <div className="col-md-4 custom-with ">
              <label
                className="block text-grey-darker text-xl leading-3 mb-2"
                htmlFor="sousPrenom"
              >
                PRENOMS <strong style={{ color: "#cc992d" }}>*</strong>
              </label>
              <Field
                className="appearance-none border custom-with-label rounded text-xl font-mono w-full  p-2 text-grey-darker"
                name="sousPrenom"
                id="sousPrenom"
                type="text"
                placeholder="PRENOMS"
                // readOnly={true}
              />
              <p className="text-red-600 dark:text-red-500">
                {" "}
                {errors.sousPrenom && touched.sousPrenom
                  ? errors.sousPrenom
                  : null}
              </p>
            </div>
            <div className="col-md-4 custom-with ">
              <label
                className="block text-grey-darker text-xl leading-3 mb-2"
                htmlFor="sousDateNaissance"
              >
                DATE DE NAISSANCE
              </label>
              <Field
                className="appearance-none border custom-with-label rounded  text-xl font-mono w-full  p-2 text-grey-darker"
                name="sousDateNaissance"
                id="sousDateNaissance"
                type="date"
                placeholder="DATE DE NAISSANCE"
                // readOnly={true}
              />
              <p className=" text-red-800 dark:text-red-800">
                {" "}
                {errors.sousDateNaissance && touched.sousDateNaissance
                  ? errors.sousDateNaissance
                  : null}
              </p>
            </div>
            {/*</div>*/}
            {/*<div className="flex cutom-box">*/}
            <div className="col-md-4 custom-with ">
              <label
                className="block text-grey-darker text-xl leading-3 mb-2"
                htmlFor="sousContactTel"
              >
                CONTACTS TELEPHONIQUE{" "}
                <strong style={{ color: "#cc992d" }}>*</strong>
              </label>
              <Field
                className="appearance-none border custom-with-label rounded text-xl font-mono w-full  p-2 text-grey-darker"
                name="sousContactTel"
                id="sousContactTel"
                type="text"
                placeholder="CONTACTS TELEPHONIQUE"
              />
              <p className="text-red-600 dark:text-red-500">
                {errors.sousContactTel && touched.sousContactTel
                  ? errors.sousContactTel
                  : null}
              </p>
            </div>
            <div className="col-md-4 custom-with">
              <label
                className="block text-grey-darker text-xl leading-3 mb-2"
                htmlFor="sousAdressePostale"
              >
                ADRESSE POSTALE
              </label>
              <Field
                className="appearance-none border custom-with-label rounded text-xl font-mono w-full  p-2 text-grey-darker"
                name="sousAdressePostale"
                id="sousAdressePostale"
                type="text"
                placeholder="ADRESSE POSTALE"
              />
              <p className="text-red-600 dark:text-red-500">
                {" "}
                {errors.sousAdressePostale && touched.sousAdressePostale
                  ? errors.sousAdressePostale
                  : null}
              </p>
            </div>
            <div className="col-md-4 custom-with">
              <label
                className="block text-grey-darker text-xl leading-3 mb-2"
                htmlFor="numero_police"
              >
                NUMERO DE POLICE
              </label>
              <InputTags
                className=" input-tags appearance-none border custom-with-label rounded font-mono w-full  p-1 text-grey-darker "
                values={numPolices}
                onTags={(value) => setNumPolices(value.values)}
              />
              {/* <p className="text-red-600 dark:text-red-500">{error}</p> */}
            </div>
          </div>
          <div className="flex items-center justify-start cutom-box my-2">
            <div className="checkbox-container">
              <input
                type="checkbox"
                id="my-checkbox"
                checked={isChecked}
                onChange={(e) => handleCheckboxChange(e)}
              />
              <label
                htmlFor="my-checkbox"
                className="flex items-center justify-center"
              >
                Veuillez cocher cette case svp si le client est aussi le défunt.
              </label>
            </div>
          </div>

          <div className="flex mt-3 justify-between align-center text-center btn-container souscripteur-btn-mobile">
            <div className="w-1/2 mx-2 flex justify-center souscripteur-btn-back">
              <button
                onClick={(e) => handleReturn(e)}
                type="button"
                className="return-btn flex recherche-btn justify-center items-center px-3 py-2 text-2xl font-medium rounded"
              >
                <AiOutlineArrowLeft className="mr-2" /> Précédent
              </button>
            </div>
            <div className="w-1/2 mx-2 flex justify-center">
              <button
                type="submit"
                className="flex recherche-btn justify-center items-center px-3 py-2 text-2xl font-medium text-white rounded border border-blue-700 focus:ring-4 focus:outline-none focus:bg-amber-400 hover:bg-amber-400 focus:bg-amber-400"
              >
                Suivant <AiOutlineArrowRight className="ml-2" />
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Souscripteur;
