import React from "react";
import { Link } from "react-router-dom";
import { FcAssistant } from "react-icons/fc";
import { TfiWrite } from "react-icons/tfi";
import { MdFollowTheSigns } from "react-icons/md";
import "./landing.css";
import { FaTeamspeak } from "react-icons/fa";

const LandingPage = () => {
  if (localStorage.length > 0) {
    localStorage.clear();
  } else {
    console.log("localStorage is empty.");
  }
  return (
    <section
      style={{
        backgroundImage: `url(./img/backgrounds/consolation4.png)`,
        top: "center",
        width: "100%",
      }}
      id="hero"
      className="d-flex align-items-center justify-content-center"
    >
      <div className="container" data-aos="fade-up">
        <div
          className="row justify-content-center bvn"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div className="col-12 welcome-container p-4">
            <h1 className="bvn-phrase">
              A vos côtés en cas de coup dur<span></span>
            </h1>
            <h3 className="plate-form">
              Comptez sur nous à tout moment pour vos déclarations et suivis de
              sinistre en ligne.
            </h3>
          </div>
        </div>
        <div
          className="row mt-5 justify-content-center"
          data-aos="zoom-in"
          data-aos-delay="250"
        >
          <div className="col-12 col-lg-4 mb-4 ">
            <Link className="box-link" data-tour="step-1" to="search">
              <div className="icon-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* #4b7d04 */}
                  <TfiWrite className="decla-icon" size={100} color="#4b7d04" />
                </div>
                <h3 className="souhaite">Je déclare un sinistre</h3>
              </div>
            </Link>
          </div>
          <div className="col-6 col-lg-4 mb-4">
            <Link className="box-link" data-tour="step-2" to="login">
              <div className="icon-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MdFollowTheSigns size={100} color="#4b7d04" />
                </div>
                <h3 className="souhaite"> Je suis mon dossier</h3>
              </div>
            </Link>
          </div>
          <div className="col-6 col-lg-4 mb-4">
            <Link className="box-link" data-tour="step-3" to="assistance">
              <div className="icon-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* SiGoogleassistant */}
                  <FaTeamspeak size={100} color="#4b7d04" />
                </div>
                <h3>Je me fais assister</h3>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingPage;
