import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import qs from "qs";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import actions_config from "../../../../actions/config.js";
import Breadcrumb from "./Breadcrumb.js";
import FaqItemsContentViewer from "./FaqItemsContentViewer";
import AlertContent from "../../../alertContent/AlertContent.js";
import listelementcategory from "../../../../data/faq-liste-elements-categorie.json";
import listcategory from "../../../../data/faq-liste-categories.json";

export default function Viewer() {
  const defaultBreadCrumb = {
    key: 0,
    context: "index",
    title: "Categories",
  };
  const [breadCrumbItems, setBreadCrumbItems] = useState([defaultBreadCrumb]);
  const [dataViewer, setDataViewer] = useState([]);
  const [itemsViewContent, setItemsViewContent] = useState([]);

  useEffect(() => {
    settleDefault();
  }, []);

  const settleDefault = () => {
    // axios(actions_config.axios_config)
    //   .then(function(response) {
    var response = listcategory;
    console.log(response, "viewer faq");
    let _ = response.data;
    let data = [];

    _.map((__) => {
      data.push({ ...__, context: "category" });
    });
    setDataViewer(data);
    //   })
    //   .catch(function(error) {
    //     console.log(error);
    //   });
  };
  const renderBreadCrumbContainer = () => {
    return (
      <div className="card-header flex justify-between items-center py-3">
        <Breadcrumb breadcrumbs={breadCrumbItems} onClickGoTo={onClickGoTo} />
        <div className="text-3xl text-center">FAQ</div>
      </div>
    );
  };

  const renderViewContent = () => {
    return <FaqItemsContentViewer itemsContentView={itemsViewContent} />;
  };

  const requestItem = (_item) => {
    // axios({
    //     ...actions_config.axios_config,
    //     method: 'post',
    //     data: qs.stringify({
    //         'token': `${_item.key}`
    //     }),

    //     url: actions_config.axios_base_url + "/list-items-category-faq"
    // })
    //     .then(function (response) {
    var response = listelementcategory;
    console.log(response, "response from requestItem");
    let _ = response.data;
    let data = [];
    let keyContextValue = "category";

    if (_.contents.length === 0) {
      _ = _.subcategories;
      _.map((__) => {
        data.push({ ...__, context: keyContextValue });
      });
    } else {
      keyContextValue = "content";
      _ = _.contents;
      _.map((__) => {
        data.push({ ...__, context: keyContextValue });
      });
      setItemsViewContent(data);
    }
    setDataViewer(data);
    // })
    // .catch(function (error) {
    //     console.log(error);
    // });
  };

  const onClickGoTo = (_item) => {
    if (_item.context === "index" && breadCrumbItems.length > 1) {
      setBreadCrumbItems([defaultBreadCrumb]);
      settleDefault();
      setItemsViewContent([]);
    } else if (_item.context === "category") {
      const indexGoTo = breadCrumbItems.findIndex((_v) => _v.key === _item.key);
      let _breadCrumbs = [];
      if (indexGoTo !== -1) {
        _breadCrumbs = breadCrumbItems.filter((_v, _i) => _i <= indexGoTo);
        setItemsViewContent([]);
        handleClickDataViewItem(_item);
        setBreadCrumbItems(_breadCrumbs);
      }
    } else {
      //
    }
  };

  const handleClickDataViewItem = (_item) => {
    setBreadCrumbItems([
      ...breadCrumbItems,
      {
        key: _item.key,
        context: _item.context,
        title: _item.title,
      },
    ]);
    if (_item.context !== "content") {
      requestItem(_item);
    }
  };
  const renderDataViewerItem = (_item) => {
    return (
      <div key={_item.key} className="col-md-4 mb-2">
        <Link
          style={{ textDecoration: "none", color: "white" }}
          data-tour="step-1"
          onClick={() => handleClickDataViewItem(_item)}
        >
          <div className="icon-box">
            <h3>{_item.title}</h3>
          </div>
        </Link>
      </div>
    );
  };

  if (dataViewer.length === 0) return <AlertContent />;
  return (
    <>
      {renderBreadCrumbContainer()}
      <div className="card-body p-4 d-flex align-items-center justify-content-center">
        <div
          className="container d-flex flex-column justify-content-between "
          data-aos="fade-up"
        >
          <div
            className="row mb-2 justify-content-center"
            data-aos="zoom-in"
            data-aos-delay="250"
          >
            {itemsViewContent.length > 0
              ? renderViewContent()
              : dataViewer.map((_item) => renderDataViewerItem(_item))}
          </div>
        </div>
      </div>
    </>
  );
}
