import React, { useContext, useState, useEffect, useCallback } from "react";
import { AppContext } from "../../../context/ContextApi";
import { Link, useNavigate, redirect } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

const validationSchema = Yup.object().shape({});

const Confirm = () => {
  const {
    individuel,
    individuelNextStep,
    individuelPreviousStep,
    fileName,
    fileName2,
    fileName3,
    isCheck,
    selectedOption,
    setSelectedOption,
    selectedItem,
    setSelectedItem,
    declareAcceuil,
    setDeclareAcceuil,
    successful,
    setSuccessful,
    message,
    setMessage,
    showAlert,
    researchResult,
    setSearchResult,
    numPolices,
    setNumPolices,
    userDate,
  } = useContext(AppContext);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("NONE/NONE");
  const navigate = useNavigate();

  //getting data from localstorage
  // let data = JSON.parse(localStorage.getItem('data'));
  // let response = data.data.client.contrats;

  const date = new Date(individuel.sousDateNaissance);
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const formattedDate = date.toLocaleDateString("fr-FR", options);

  const date2 = new Date(userDate);
  const options2 = { year: "numeric", month: "numeric", day: "numeric" };
  const formattedDate2 = date2.toLocaleDateString("fr-FR", options2);
  //   console.log("date de Naissance", userDate);
  //   console.log("individuel.defDateNaissance", individuel.defDateNaissance);

  const date3 = new Date(individuel.defDateDeces);
  const options3 = { year: "numeric", month: "numeric", day: "numeric" };
  const formattedDate3 = date3.toLocaleDateString("fr-FR", options3);

  const handleSubmit = (values) => {
    individuelNextStep(values);
  };
  const handleBack = (values) => {
    individuelPreviousStep(values);
  };

  const handleValidation = (values) => {
    // console.log("values", values);
  };
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={individuel}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form>
          <h3 className="section-title section-title-mobile">
            RESUME DES INFORMATIONS SAISIES
          </h3>
          <div className="row flex cutom-box">
            <dl className="col-md-6 custom-with text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700 sous-resume">
              <div className="card-header py-2 text-black text-xl text-center border-grey-lighter">
                SOUSCRIPTEUR
              </div>
              <div className="flex flex-wrap p-2 cutom-box">
                <dt className="fcustom-with-label text-xl md:text-xl dark:text-gray-400">
                  POLICES:
                </dt>
                {numPolices.map((itemChecked, index) => {
                  return (
                    <div key={index}>
                      <dd
                        key={index}
                        className="text-white ml-2 custom-with-label text-xl mt-2 leading-3"
                      >
                        {itemChecked}
                        {(itemChecked.length < 2 || index === (itemChecked.length - 1)) ? "" : ","}
                      </dd>
                    </div>
                  );
                })}
              </div>
              <div className="flex p-2 cutom-box">
                <dt className="custom-with-label text-xl md:text-xl dark:text-gray-400">
                  NOM :
                  <span className="text-white ml-2 custom-with-label text-xl mt-2 leading-3">
                    {individuel.sousNom}
                  </span>
                </dt>
              </div>
              <div className="flex p-2 cutom-box">
                <dt className="custom-with-label text-xl md:text-xl dark:text-gray-400">
                  PRENOMS :
                  <span className=" text-white ml-2 custom-with-label text-xl mt-2  leading-3">
                    {individuel.sousPrenom}
                  </span>
                </dt>
              </div>
              <div className="flex p-2 cutom-box">
                <dt className="custom-with-label text-xl md:text-xl dark:text-gray-400">
                  DATE DE NAISSANCE :
                  <span className=" text-white ml-2 custom-with-label text-xl mt-2 leading-3">
                    {formattedDate}
                  </span>
                </dt>
              </div>
              <div className="flex p-2 cutom-box">
                <dt className="custom-with-label text-xl md:text-xl dark:text-gray-400">
                  CONTACT :
                  <span className=" text-white ml-2 custom-with-label text-xl mt-2 leading-3">
                    {individuel.sousContactTel}
                  </span>
                </dt>
              </div>
              <div className="flex p-2 cutom-box">
                <dt className="custom-with-label text-xl md:text-xl dark:text-gray-400">
                  ADRESSE POSTALE :
                  <span className=" text-white ml-2 custom-with-label text-xl mt-2 leading-3">
                    {individuel.sousAdressePostale}
                  </span>
                </dt>
              </div>
            </dl>
            <dl className="col-md-6 custom-with text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700 sous-defunt">
              <div className="card-header py-2 text-black text-xl border-b text-center  border-grey-lighter">
                DEFUNT
              </div>
              <div className="flex p-2 cutom-box">
                <dt className="custom-with-label text-xl md:text-xl dark:text-gray-400">
                  NOM & PRENOMS :
                  <span className=" text-white ml-2 custom-with-label text-xl mt-2  leading-3">
                    {individuel.assure}
                  </span>
                </dt>
              </div>
              <div className="flex p-2 cutom-box">
                <dt className=" custom-with-label text-xl md:text-xl dark:text-gray-400">
                  DATE DE NAISSANCE :
                  <span className="text-white ml-2 custom-with-label text-xl  mt-2 leading-3">
                    {formattedDate2}
                  </span>
                </dt>
              </div>
              <div className="flex p-2 cutom-box">
                <dt className="custom-with-label text-xl md:text-xl dark:text-gray-400">
                  LIEU DE NAISSANCE :
                  <span className=" text-white ml-2 custom-with-label text-xl mt-2  leading-3">
                    {individuel.defLieuNaissance}
                  </span>
                </dt>
              </div>
              <div className="flex p-2 cutom-box">
                <dt className="custom-with-label text-xl md:text-xl dark:text-gray-400">
                  PROFESSION :
                  <span className=" text-white ml-2 custom-with-label text-xl mt-2 leading-3">
                    {individuel.defProfession}
                  </span>
                </dt>
              </div>
              <div className="flex p-2 cutom-box">
                <dt className=" custom-with-label text-xl md:text-xl dark:text-gray-400">
                  DATE DE DECES :
                  <span className=" text-white ml-2 custom-with-label text-xl mt-2 leading-3">
                    {formattedDate3}
                  </span>
                </dt>
              </div>
              <div className="flex p-2 cutom-box">
                <dt className=" custom-with-label text-xl md:text-xl dark:text-gray-400">
                  LIEU DE DECES :
                  <span className=" text-white ml-2 custom-with-label text-xl mt-2 leading-3">
                    {individuel.defLieuDeces}
                  </span>
                </dt>
              </div>
            </dl>
          </div>
          <div className="flex justify-between align-center text-center btn-container souscripteur-btn-mobile">
            <div className="w-1/2 mx-2 flex justify-center">
              <button
                onClick={() => handleBack("")}
                type="button"
                className="return-btn flex recherche-btn justify-center items-center py-2 px-3 text-2xl font-medium rounded "
              >
                <AiOutlineArrowLeft className="mr-2" /> Précédent
              </button>
            </div>
            <div className="w-1/2 mx-2 flex justify-center">
              <button
                onClick={() => handleValidation(values)}
                type="submit"
                className="flex recherche-btn justify-center items-center py-2 px-3 text-2xl font-medium text-white  rounded border border-blue-700 focus:ring-4 focus:outline-none focus:bg-amber-400 hover:bg-amber-400 focus:bg-amber-400"
              >
                Suivant <AiOutlineArrowRight className="ml-2" />
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Confirm;
