import axios from "axios";
import qs from "qs";
import React, { useContext, useState, useCallback, useEffect } from "react";
import { AppContext } from "../../../context/ContextApi";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./loginform.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineLogin,
} from "react-icons/ai";
import { MdFollowTheSigns } from "react-icons/md";
import global_variables from "../../controllers/globalVar.js";
import Loader from "../../loading/Loading.js";
import rechercheNum from "../../../data/rechercheParNum.json";

const validationSchema = Yup.object().shape({
  codeinput: Yup.string().required("Ce champ est obligatoire"),
});

const LoginForm = () => {
  const {
    individuel,
    individuelNextStep,
    individuelPreviousStep,
    fileName,
    fileName2,
    fileName3,
    isCheck,
    selectedOption,
    setSelectedOption,
    selectedItem,
    setSelectedItem,
    declareAcceuil,
    setDeclareAcceuil,
    successful,
    setSuccessful,
    message,
    setMessage,
    showAlert,
    alert,
    researchResult,
    setSearchResult,
  } = useContext(AppContext);
  const { corporate, corporateNextStep, corporatePreviousStep } = useContext(
    AppContext
  );
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("NONE/NONE");
  const [usedValues, setUsedValues] = useState({});
  const navigate = useNavigate();
  const initialValues = {
    codeinput: "",
  };

  const handleNouveauCode = () => {
    navigate("/newcode");
  };

  const handleSubmit = useCallback(
    (values) => {
      setLoading(true);
      // setTimeout(() => {
      //   setLoading(true);
      // }, 2000);
      // toast.success("Bienvenue sur suivre son dossier!", {
      //   position: "top-center",
      //   autoClose: 10000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "dark",
      // });
      // navigate("/suivrehome");
      // if (!values.codeinput) return null;
      // var data = qs.stringify({
      //   numero_declaration: `${values.codeinput}`,
      // });
      // var config = {
      // method: "post",
      // maxBodyLength: Infinity,
      // url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/show-declaration`,
      // headers: {
      //   "Content-Type": "application/x-www-form-urlencoded",
      // },
      // data: data,
      // };
      setLoading(true);
      // console.log(config, "config");
      // axios(config)
      //   .then((response) => {
      //     console.log("response", response);
      var response = rechercheNum;
      localStorage.setItem("user", JSON.stringify(response.data));
      console.log("response", response);
      if (response.data.length === 0) {
        setLoading(false);
        toast.error(`${response.data.error}`, {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        setTimeout(() => {
          // After 30 seconds, navigate to the next page
          toast.success("Bienvenue sur suivre son dossier!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          navigate("/suivrehome");
        }, 5000);
      }
      // })
      // .catch((error) => {
      //   setLoading(false);
      //   toast.error(
      //     "Désolé! Le service que vous tentez de joindre ne répond pas veuillez réessayer plus tard..",
      //     {
      //       position: "top-center",
      //       autoClose: 10000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //       theme: "dark",
      //     }
      //   );
      //   console.log(error);
      // });
    },
    [selectedOption, selectedItem, setSearchResult, researchResult]
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="font-sans antialiased bg-grey-lightest">
            <div className="w-full bg-grey-lightest lightest-container">
              <div className="container lightest-inner">
                <div className="card w-full rounded shadow lightest-shadow">
                  <div className="flex justify-center items-center card-header py-3 text-3xl text-center renseignement">
                    <MdFollowTheSigns size={60} color="#121b44" /> SUIVRE MON
                    DOSSIER
                  </div>
                  <div className="card-body lighest-main-login">
                    <div className="flex justify-evenly my-3 items-center cutom-box">
                      <div className="w-full mx-2 flex-col justify-center align-center code-box cutom-with">
                        <Field
                          className="flex justify-around p-2 items-center text-center appearance-none border custom-with-label rounded text-xl font-mono w-full text-grey-darker"
                          name="codeinput"
                          id="codeinput"
                          type="text"
                          placeholder="Entrez votre numéro de déclaration"
                        />
                        <p className="text-xl text-red-600 dark:text-red-500">
                          {errors.codeinput && touched.codeinput
                            ? errors.codeinput
                            : null}
                        </p>
                      </div>
                    </div>

                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Loader />
                      </div>
                    ) : (
                      <div className="flex  justify-center mb-5 btn-container souscripteur-btn-mobile">
                        <div className="flex justify-center align-center text-center police-btn-container">
                          <Link
                            to="/"
                            type="button"
                            className="w-1/2 flex justify-center items-center login-btn-mobile return-btn recherche-btn mx-2 py-2 px-3 w-100 text-2xl font-medium rounded"
                            style={{ textDecoration: "none" }}
                          >
                            <AiOutlineArrowLeft className="mr-2" /> Retour
                          </Link>
                          <button
                            type="submit"
                            className="w-1/2 flex justify-center login-btn flex recherche-btn justify-center items-center mx-2 py-2 px-3 w-100 text-2xl font-medium text-black bg-amber-500  rounded border border-blue-700 focus:ring-4 focus:outline-none focus:bg-amber-400 hover:bg-amber-400 focus:bg-amber-400"
                          >
                            Vérifier <AiOutlineLogin className="ml-2" />
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="my-3 btn-container souscripteur-btn-mobile">
                      <div className="w-full flex justify-center items-center renvoyer-numero-container">
                        <p className="text-gray-300 text-2xl m-2 leading-3 avez-vous-oublier">
                          J'ai oublié mon numéro de déclaration.
                        </p>
                        <button
                          type="button"
                          onClick={handleNouveauCode}
                          className="flex recherche-btn justify-center items-center p-2 text-xl font-medium text-white rounded border border-blue-700 focus:ring-4 focus:outline-none focus:bg-amber-400 hover:bg-amber-400 focus:bg-amber-400"
                        >
                          Renvoyer le numéro
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
