import React, { createContext, useState, useEffect } from "react";
import { GiCheckMark } from "react-icons/gi";
import { TiWarning } from "react-icons/ti";
import { MdOutlinePending } from "react-icons/md";

//creation du context Api;
export const AppContext = createContext();

const AppContextProvider = (props) => {
  const [fileName, setFileName] = useState("pas upload");
  const [fileName2, setFileName2] = useState("pas upload");
  const [fileName3, setFileName3] = useState("pas upload");
  const [isCheck, setIsCheck] = useState([]);
  const [nature, setNature] = useState([]);
  const [open, setOpen] = useState(false);
  const [openTour, setOpenTour] = useState(false);
  const [step, setStep] = useState(1);
  const [faqstep, setFaqStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState("1");
  const [declareAcceuil, setDeclareAcceuil] = useState("");
  const [selectedItem, setSelectedItem] = useState(true);
  const [successful, setSuccessful] = useState(false);
  const [researchResult, setSearchResult] = useState({});
  const [defunDate, setDefunDate] = useState("");
  const [message, setMessage] = useState("");
  const steps = [
    "Souscripteur",
    "Defunt",
    "Declarant",
    "Beneficiaires",
    "Review",
    "Confirm",
  ];
  const [showModal, setShowModal] = useState(false);
  const [numPolices, setNumPolices] = useState([]);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    variant: "success",
  });
  const [error, setError] = useState({
    show: false,
    message: "",
  });
  const [isChecked, setIsChecked] = useState(false);
  const [myData, setMyData] = useState({});
  const [updateContextStoreData, setUpdateContextStoreData] = useState(false);
  const [defuntInfo, setDefuntInfo] = useState(false);
  const [userDate, setUserDate] = useState("");
  const [userLieu, setUserLieu] = useState("");
  const [userCheckedData, setUserCheckedData] = useState({});

  // this function is for implementing souscripteur checkbox

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    // console.log("isChecked", isChecked);
    let storeData2 = localStorage.getItem("checkbox");

    if (storeData2 !== null) {
      storeData2 = JSON.parse(storeData2);
      setMyData(storeData2);
      if (event.target.checked) {
        setDefuntInfo(true);
        let clientBirthDate = `${storeData2.date_naissance
          .toString()
          .substr(0, 4)}-${storeData2.date_naissance
          .toString()
          .substr(4, 2)}-${storeData2.date_naissance.toString().substr(6, 2)}`;

        // setIndividuel({
        //   ...individuel,
        //   assure: storeData2.nom_client + " " + storeData2.prenoms_client,
        //   defDateNaissance: clientBirthDate,
        // });
        let userInfo = {
          name: storeData2.nom_client,
          prenoms: storeData2.prenoms_client,
          dob: clientBirthDate,
        };
        setUserCheckedData(userInfo);
      } else {
        setDefuntInfo(false);
        setIndividuel({
          ...individuel,
          assure: "",
          defDateNaissance: "",
        });
      }
    }
  };

  //function for retrievingData from the local sotorage and modifying old data

  const retrieveData = (fromDataStore, setMyData) => {
    if (fromDataStore) {
      // let clientBirthDate = `${fromDataStore.date_naissance
      //   .toString()
      //   .substr(0, 4)}-${fromDataStore.date_naissance
      //   .toString()
      //   .substr(4, 2)}-${fromDataStore.date_naissance.toString().substr(6, 2)}`;

      setIndividuel({
        ...individuel,
        sousNom: "KOUADIO",
        sousPrenom: "NICODEME",
        sousDateNaissance: "",
        sousContactTel: "",
        sousAdressePostale: "",
        defDateNaissance: "",
      });
    } else {
      setIndividuel({
        ...individuel,
        sousNom: "",
        sousPrenom: "",
        sousDateNaissance: "",
        sousContactTel: "",
        sousAdressePostale: "",
        defDateNaissance: "",
      });
    }
  };
  // trying to get the most updated state with the useEffect hook

  useEffect(() => {
    let storeData = localStorage.getItem("userLamdaInfo");

    if (storeData !== null) {
      storeData = JSON.parse(storeData);
      setMyData(storeData);
      // console.log("call individuel", storeData);
      retrieveData(storeData, setMyData);
      setUserLieu(storeData.lieu_naissance);
      // console.log("userLieu", storeData.lieu_naissance);
    }
  }, [updateContextStoreData]);

  // state to keep track of static added input fields
  const [staticFields, setStaticFields] = useState({ text: "", file: null });
  // state to keep track of dynamically added input fields
  const [unicFormStaticBox, setUnicFormStaticBox] = useState("");
  const [unicFormStaticFields, setUnicFormStaticFields] = useState({
    text: "",
    file: null,
  });
  // state to keep track of dynamically added input fields
  const [staticBox, setStaticBox] = useState("");
  const [dynamicBox, setDynamicBox] = useState([]);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [newPieceDynamicBox, setNewPieceDynamicBox] = useState([]);
  const [newPieceDynamicFields, setNewPieceDynamicFields] = useState([]);
  const [staticSelectedCheckboxes, setStaticSelectedCheckboxes] = useState([]);
  const [dynamicSelectedCheckboxes, setDynamicSelectedCheckboxes] = useState(
    []
  );
  const [searchResults, setSearchResults] = useState({});
  const [singleSearch, setSingleSearch] = useState({});
  console.log("resrchResults from context", searchResults);
  const [individuel, setIndividuel] = useState({
    sousContactTel: "",
    sousAdressePostale: "",
    sousNom: "KOUADIO",
    sousPrenom: "NICODEME",
    sousDateNaissance: "",
    cause_deces: "",
    assure: "",
    civilite: "",
    police: "",
    police_nature: "",
    defNom: "",
    defPrenoms: "",
    defDateNaissance: "",
    defLieuNaissance: "",
    defProfession: "",
    defDateDeces: "",
    defLieuDeces: "",
    declaNom: "",
    qualite_declarant: "",
    declaPrenom: "",
    declaContact: "",
    autreContact: "",
    declaAdressElectro: "",
    declaAdressePost: "",
    declaLienParente: "",
    benedoc1: "",
    benedoc2: "",
    benedoc3: "",
    fichier_nature: "",
    fichier_nature2: "",
    fichier_nature3: "",
  });
  const [rdv, setRdv] = useState({
    rdvNom: "",
    rdvPrenom: "",
    rdvTelephone: "",
    rdvMotif: "",
    rdvHeure: "",
    rdvDate: "",
    rdvResidence: "",
  });

  //showAlert function
  function showAlert(message, variant = "success", seconds = 5000) {
    setAlert({
      show: true,
      message,
      variant,
    });

    setTimeout(() => {
      setAlert({
        show: false,
        message: "",
        variant: "success",
      });
    }, seconds);
  }
  //showErrorMessage function
  function showErrorMessage(message, seconds = 5000) {
    setError({
      show: true,
      message,
    });

    setTimeout(() => {
      setError({
        show: false,
        message: "",
      });
    }, seconds);
  }

  //function for emptying all input fields
  const handleEmpty = (navigate, path) => {
    setIndividuel({
      sousContactTel: "",
      sousAdressePostale: "",
      sousNom: "",
      sousPrenom: "",
      sousDateNaissance: "",
      cause_deces: "",
      assure: "",
      civilite: "",
      police: "",
      police_nature: "",
      defNom: "",
      defPrenoms: "",
      defDateNaissance: "",
      defLieuNaissance: "",
      defProfession: "",
      defDateDeces: "",
      defLieuDeces: "",
      declaNom: "",
      qualite_declarant: "",
      declaPrenom: "",
      declaContact: "",
      declaAdressElectro: "",
      declaAdressePost: "",
      declaLienParente: "",
      benedoc1: "",
      benedoc2: "",
      benedoc3: "",
      fichier_nature: "",
      fichier_nature2: "",
      fichier_nature3: "",
    });
    setStep(1);
    setIsCheck([]);
    navigate(`/${path}`);
    setSelectedItem(true);
    setSelectedOption("1");
    // Refresh the page
    window.location.reload();
  };

  const handleEmpty2 = () => {
    setIndividuel({
      sousContactTel: "",
      sousAdressePostale: "",
      sousNom: "",
      sousPrenom: "",
      sousDateNaissance: "",
      cause_deces: "",
      assure: "",
      civilite: "",
      police: "",
      police_nature: "",
      defNom: "",
      defPrenoms: "",
      defDateNaissance: "",
      defLieuNaissance: "",
      defProfession: "",
      defDateDeces: "",
      defLieuDeces: "",
      declaNom: "",
      qualite_declarant: "",
      declaPrenom: "",
      declaContact: "",
      declaAdressElectro: "",
      declaAdressePost: "",
      declaLienParente: "",
      benedoc1: "",
      benedoc2: "",
      benedoc3: "",
      fichier_nature: "",
      fichier_nature2: "",
      fichier_nature3: "",
    });
  };

  //corporate implementation

  //individuel
  // Proceed to next step
  const individuelNextStep = (newData) => {
    setIndividuel({ ...individuel, ...newData });
    setStep(step + 1);
  };
  // Proceed to previous step
  const individuelPreviousStep = (newData) => {
    setIndividuel({ ...individuel, ...newData });
    setStep(step - 1);
  };
  //rdv form
  // Proceed to next step
  const rdvNextStep = (newData) => {
    setRdv({ ...rdv, ...newData });
    setStep(step + 1);
  };
  // Proceed to previous step
  const rdvPreviousStep = (newData) => {
    setRdv({ ...rdv, ...newData });
    setStep(step - 1);
  };

  return (
    <AppContext.Provider
      value={{
        open,
        setOpen,
        openTour,
        setOpenTour,
        individuel,
        setIndividuel,
        step,
        individuelNextStep,
        individuelPreviousStep,
        selectedOption,
        setSelectedOption,
        selectedItem,
        setSelectedItem,
        declareAcceuil,
        setDeclareAcceuil,
        successful,
        setSuccessful,
        message,
        setMessage,
        alert,
        showAlert,
        researchResult,
        setSearchResult,
        fileName,
        setFileName,
        fileName2,
        setFileName2,
        fileName3,
        setFileName3,
        isCheck,
        setIsCheck,
        nature,
        setNature,
        handleEmpty,
        showModal,
        setShowModal,
        steps,
        showErrorMessage,
        error,
        faqstep,
        setFaqStep,
        setDefunDate,
        defunDate,
        // updateData
        // showCheck
        setUpdateContextStoreData,
        staticFields,
        setStaticFields,
        dynamicFields,
        setDynamicFields,
        staticSelectedCheckboxes,
        setStaticSelectedCheckboxes,
        dynamicSelectedCheckboxes,
        setDynamicSelectedCheckboxes,
        staticBox,
        setStaticBox,
        dynamicBox,
        setDynamicBox,
        newPieceDynamicBox,
        setNewPieceDynamicBox,
        newPieceDynamicFields,
        setNewPieceDynamicFields,
        unicFormStaticBox,
        setUnicFormStaticBox,
        unicFormStaticFields,
        setUnicFormStaticFields,
        numPolices,
        setNumPolices,
        rdvNextStep,
        rdvPreviousStep,
        rdv,
        setRdv,
        isChecked,
        setIsChecked,
        handleCheckboxChange,
        handleEmpty2,
        defuntInfo,
        userDate,
        setUserDate,
        userLieu,
        setUserLieu,
        userCheckedData,
        searchResults,
        setSearchResults,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
