import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./individuel.css";
import { AppContext } from "../../../context/ContextApi";
import { FaThumbsUp } from "react-icons/fa";
import { TfiWrite } from "react-icons/tfi";
import { MdFollowTheSigns } from "react-icons/md";

const Success = () => {
  const {
    selectedOption,
    setSelectedOption,
    selectedItem,
    setSelectedItem,
    declareAcceuil,
    setDeclareAcceuil,
    handleEmpty,
  } = useContext(AppContext);
  let navigate = useNavigate();
  let data = JSON.parse(localStorage.getItem("numero"));
  let startingData = {
    numero_declaration: "054/2023",
  };
  let response = data?.data || startingData;
  useEffect(() => {}, [selectedOption, selectedItem]);
  return (
    <div className="font-sans antialiased bg-grey-lightest">
      <div className="container w-full bg-grey-lightest">
        <div className="card w-full bg-white rounded shadow lightest-shadow">
          <div className="card-body sm:flex justify-evenly items-center p-4 md:flex flex-col justify-center items-center lg:flex justify-center items-center">
            <h2 className="flex my-3 text-4xl justify-center items-center text-success custom-with-label">
              <FaThumbsUp size={70} />
            </h2>
            <h3 className="my-3 text-4xl text-white custom-with-label">
              Votre déclaration de sinistre a bien été enregistrée.
            </h3>
            <div className="flex flex-col justify-conter align-center w-100">
              <p className="text-3xl plate-form text-secondary font-normal custom-with-label">
                Votre numéro de déclaration est :{" "}
                <label
                  className="font-bold text-4xl"
                  style={{ color: "#fb8b249c" }}
                >
                  {response?.numero_declaration}
                </label>
              </p>
              <div className="row justify-center btn-container souscripteur-btn-mobile">
                {/* <div className="col-md-6">
                                    <button onClick={() => handleEmpty(navigate, 'declare')} type="submit" className="flex nouvelle-declaration recherche-btn justify-center items-center m-2 text-2xl h-20 w-full font-medium text-white rounded border border-blue-700 focus:ring-4 focus:outline-none focus:bg-amber-400 hover:bg-amber-400 focus:bg-amber-400">
                                        <TfiWrite size={40} color="#caa33f" className='mr-2' /> NOUVELLE DECLARATION
                                    </button>
                                </div> */}
                <div className="col-md-6">
                  <button
                    onClick={() => handleEmpty(navigate, "login")}
                    type="button"
                    className="return-btn suivre-son-dossier flex recherche-btn justify-center items-center m-2 h-20 w-full text-2xl font-medium rounded"
                  >
                    <MdFollowTheSigns
                      size={40}
                      color="#fb8b249c"
                      className="mr-2"
                    />{" "}
                    SUIVRE MON DOSSIER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
