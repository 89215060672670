import React from "react";
import "../globalStyle.css";
import { Link } from "react-router-dom";

const TutorialContainer = () => {
  return (
    <section
      id="hero"
      style={{
        backgroundImage: `url(./img/backgrounds/assistance4.jpg)`,
        top: "center",
      }}
      className="d-flex align-items-center justify-content-center"
    >
      <div className="container" data-aos="fade-up">
        <div
          className="row login-inner justify-center"
          data-aos="zoom-in"
          data-aos-delay="250"
        >
          <div className="col-12 assistanceContainer" data-aos="fade-up">
            <div className="icon-box box-mobile">
              <div className="col-xl-12 col-md-4 col-lg-8 welcome-container py-3">
                <h1 className="bvn-phrase">
                  Bienvenue au Tutorial D'utilisation
                </h1>
                <p className="plate-form text-secondary">
                  Pour faire un tour de l'application, il faut aller le guide
                  situer sur la page d'accueil. Veuillez cliquer sur la flèche
                  elle vous fera faire le tour d'utilisation
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TutorialContainer;
