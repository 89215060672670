import React, { useContext, useState, useCallback, useEffect } from "react";
import axios from "axios";
import qs from "qs";
import { AppContext } from "../../../../context/ContextApi";
import { Link, useNavigate, redirect } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "./style.css";
import * as Yup from "yup";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import global_variables from "../../../controllers/globalVar.js";
import Loader from "../../../loading/Loading.js";
import { BiSearchAlt } from "react-icons/bi";
import datajson from "../../../../data/client.json";

const validationSchema = Yup.object().shape({
  nomFam: Yup.string(),
  prenoms: Yup.string(),
  dateNais: Yup.string(),
});

const SouscripteurInfo = () => {
  const {
    selectedOption,
    setSelectedOption,
    selectedItem,
    setSelectedItem,
    declareAcceuil,
    setDeclareAcceuil,
    successful,
    setSuccessful,
    message,
    setMessage,
    showAlert,
    researchResult,
    setSearchResult,
    alert,
    setUpdateContextStoreData,
    searchResults,
    setSearchResults,
  } = useContext(AppContext);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("NONE/NONE");
  const navigate = useNavigate();
  const initialValues = {
    nomFam: "",
    prenoms: "",
    // dateNais: "",
  };

  //changes start from here ///
  const [data, setData] = useState(datajson);

  const handleSearch = (values) => {
    setLoading(true);
    const results = data.filter((item) => {
      const fullName = `${item.prenoms_client} ${item.nom_client}`;
      const nameMatch = fullName
        .toLowerCase()
        .includes(values.prenoms.toLowerCase());
      const lastNameMatch = item.nom_client
        .toLowerCase()
        .includes(values.nomFam.toLowerCase());

      return nameMatch && lastNameMatch;
    });

    setSearchResults(results);

    localStorage.setItem("userLamdaInfo", JSON.stringify(results));
    setTimeout(() => {
      // After 30 seconds, navigate to the next page
      toast.success("Félicitations! Votre recherche a bien été effectuée.", {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setUpdateContextStoreData(true);
      navigate("/signup");
    }, 3000);
    // navigate("/signup");
  };

  const handleSubmit = useCallback(
    // (values) => {
    //   if (
    //     (!values.dateNais && !values.nomFam) ||
    //     (!values.dateNais && !values.prenoms)
    //   ) {
    //     toast.error(
    //       `Veuillez vérifier les informations saisies, il faut renseigner deux champs et réessayer plus tard SVP.`,
    //       {
    //         position: "top-center",
    //         autoClose: 10000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "dark",
    //       }
    //     );
    //   } else {
    //     let transform = Number(values.dateNais.replace(/-/g, ""));
    //     var data = qs.stringify({
    //       nom: `${values.nomFam}`,
    //       prenom: `${values.prenoms}`,
    //       date_naissance: `${transform}`,
    //     });
    //     var config = {
    //       method: "post",
    //       maxBodyLength: Infinity,
    //       url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/show-subscriber`,
    //       headers: {
    //         "Content-Type": "application/x-www-form-urlencoded",
    //       },
    //       data: data,
    //     };
    //     setLoading(true);

    //     axios(config)
    //       .then((response) => {
    //         if (response.data.status === true && response.data.data.client) {
    //           toast.success(
    //             "Félicitations! Votre recherche a bien été effectuée.",
    //             {
    //               position: "top-center",
    //               autoClose: 10000,
    //               hideProgressBar: false,
    //               closeOnClick: true,
    //               pauseOnHover: true,
    //               draggable: true,
    //               progress: undefined,
    //               theme: "dark",
    //             }
    //           );

    //           localStorage.setItem(
    //             "userLamdaInfo",
    //             JSON.stringify(response.data.data.client)
    //           );
    //           localStorage.setItem(
    //             "checkbox",
    //             JSON.stringify(response.data.data.client)
    //           );
    //           setUpdateContextStoreData(true);
    //           navigate("/signup");
    //         } else if (
    //           response.data.status === false &&
    //           response.data.error.includes("web service")
    //         ) {
    //           setLoading(false);
    //           toast.error(`${response.data.error} `, {
    //             position: "top-center",
    //             autoClose: 10000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "dark",
    //           });
    //         } else {
    //           setLoading(false);
    //           toast.error(
    //             `Veuillez vérifier les informations saisies et réessayer plus tard. `,
    //             {
    //               position: "top-center",
    //               autoClose: 10000,
    //               hideProgressBar: false,
    //               closeOnClick: true,
    //               pauseOnHover: true,
    //               draggable: true,
    //               progress: undefined,
    //               theme: "dark",
    //             }
    //           );
    //         }
    //       })
    //       .catch((error) => {
    //         console.log("error from console,", error);
    //         setLoading(false);
    //         toast.error(
    //           "Désolé! Le service que vous tentez de joindre ne répond pas. Veuillez réessayer plus tard.",
    //           {
    //             position: "top-center",
    //             autoClose: 10000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "dark",
    //           }
    //         );
    //         console.log("error from client", error);
    //       });
    //   }
    // },
    [selectedOption, selectedItem, setSearchResult, researchResult]
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSearch}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="flex my-2 info-souscripteur-main">
            <div className="w-1/2 p-2 info-souscripteur-inner-box">
              <label
                className="block text-grey-darker text-start text-xl leading-3 mb-2"
                htmlFor="nomFam"
              >
                {" "}
                NOM{" "}
              </label>
              <Field
                className="text-center appearance-none border inner-box-input  rounded text-xl font-mono w-full p-2  text-grey-darker"
                name="nomFam"
                id="nomFam"
                type="text"
                placeholder="Entrez le nom du client"
              />
            </div>
            <div className="w-1/2 p-2 info-souscripteur-inner-box">
              <label
                className="block text-grey-darker text-start text-xl leading-3 mb-2"
                htmlFor="prenoms"
              >
                PRENOMS{" "}
              </label>
              <Field
                className="text-center appearance-none border inner-box-input rounded text-xl  font-mono w-full  p-2  text-grey-darker"
                name="prenoms"
                id="prenoms"
                type="text"
                placeholder="Entrez le(s) prénom(s) du  client"
              />
            </div>
            {/* <div className="w-1/3 p-2 info-souscripteur-inner-box">
              <label
                className="block text-grey-darker text-start text-xl leading-3 mb-2"
                htmlFor="dateNais"
              >
                DATE DE NAISSANCE{" "}
              </label>
              <Field
                className=" text-center appearance-none border inner-box-input rounded  text-xl font-mono w-full p-2 text-grey-darker"
                name="dateNais"
                id="dateNais"
                type="date"
                placeholder="Entrez la date de naissance du  client"
                min="1900-01-01"
                max="2030-12-31"
              />
            </div> */}
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </div>
          ) : (
            <div className="flex justify-center mt-8 mb-4">
              <div className="flex justify-center align-center text-center info-sous-btn">
                <Link
                  to="/declare"
                  type="button"
                  className="w-1/2 info-sous-return-btn flex justify-center items-center return-btn flex recherche-btn mx-2 px-3 py-2 w-100 text-2xl font-medium rounded"
                  style={{ textDecoration: "none" }}
                >
                  <AiOutlineArrowLeft className="mr-2" /> Retour
                </Link>
                <button
                  type="submit"
                  className="flex info-sous-recherche recherche-btn justify-center items-center mx-2 px-3 py-2 w-100 text-2xl font-medium text-white rounded border border-blue-700 focus:ring-4 focus:outline-none focus:bg-amber-400 hover:bg-amber-400 focus:bg-amber-400"
                >
                  Rechercher <BiSearchAlt className="ml-2" />
                </button>
              </div>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SouscripteurInfo;
