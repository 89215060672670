import React, { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "../../../context/ContextApi";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./individuel.css";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import moment from "moment";

const validationSchema = Yup.object().shape({
  assure: Yup.string().required("Ce champ est requis"),
  defDateNaissance: Yup.string(),
  defLieuNaissance: Yup.string(),
  defProfession: Yup.string(),
  defDateDeces: Yup.string().required("Ce champ est requis"),
  defLieuDeces: Yup.string(),
  cause_deces: Yup.string(),
});

const Defunt = () => {
  const {
    individuel,
    individuelNextStep,
    individuelPreviousStep,
    setDefunDate,
    defunDate,
    setIsChecked,
    setIndividuel,
    handleEmpty2,
    setUpdateContextStoreData,
    defuntInfo,
    isChecked,
    userDate,
    setUserDate,
    userLieu,
    setUserLieu,
  } = useContext(AppContext);
  const [startDate, setStartDate] = useState(new Date());
  const [error, setError] = useState(false);
  const [message, setMessate] = useState("beginning");
  const birthday = useRef(null);

  useEffect(() => {
    var today = new Date().toISOString().split("T")[0];
    document.getElementsByName("defDateDeces")[0].setAttribute("max", today);
  }, []);

  const changeUserDate = (e, callbackSettle) => {
    // let associeIndex = e.target.value;

    // let associeSelectedDate = associeIndex.date.split("/");
    // let associeDateTransformed = `${associeSelectedDate[2]}-${associeSelectedDate[1]}-${associeSelectedDate[0]}`;
    // callbackSettle("defDateNaissance", associeDateTransformed);
    if (e.target.options[e.target.selectedIndex].id) {
      const formattedDate = moment(
        associeUnic[e.target.options[e.target.selectedIndex].id].date,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      setUserDate(formattedDate);
    } else {
      setUserDate("");
    }
    // console.log(e.target.value);
    // console.log(
    //   "id",
    //   associeUnic[e.target.options[e.target.selectedIndex].id].date
    // );
    callbackSettle("assure", e.target.value);
  };

  //implement NOM_ASSURE_ASSOCIE
  let data = JSON.parse(localStorage.getItem("userLamdaInfo"));
  let response = data.contrats;
  // console.log("response", data);
  // console.log("response", response);
  let ASSOCIE = [];
  let namebycontrat = response?.map((police) => {
    return police.assures.map((associe, index) => {
      return ASSOCIE.push({
        _id: `${index}`,
        name: `${associe.NOM_ASSURE_ASSOCIE}`,
        date: `${associe.DATE_NAISSANCE_ASSURE}`,
      });
    });
  });

  let nameTab = [];
  let associeUnic = [];

  ASSOCIE.forEach((el) => {
    let _removeSpace = el.name;
    let removeSpace = el.name.replace(/\s/g, "");
    // console.log("removeSpace", removeSpace);
    if (!nameTab.includes(removeSpace) && removeSpace !== "") {
      nameTab.push(removeSpace);
      associeUnic.push(el);
    }
  });
  // console.log("associeUnic", associeUnic);

  const handleSubmit = (values) => {
    if (values.defDateNaissance === undefined) {
      values.defDateNaissance = userDate;
    }
    individuelNextStep(values);
    console.log("values", values);
    console.log("userDate type of", typeof userDate);
  };
  const handleBack = (values) => {
    individuelPreviousStep(values);
  };
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={individuel}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form>
          <h3 className="section-title section-title-mobile">
            INFORMATIONS SUR LE DEFUNT
          </h3>
          <div className="row flex cutom-box">
            {!isChecked ? (
              <div className="col-md-4 custom-with">
                <label
                  className="block text-grey-darker text-xl leading-3 mb-2"
                  htmlFor="assure"
                >
                  ASSURE
                </label>
                <select
                  className="appearance-none border custom-with-label rounded text-xl font-mono w-full p-2 text-grey-darker"
                  name="assure"
                  id="assure"
                  placeholder="ASSURE"
                  onChange={(e) => {
                    // console.log("assure", e.target.value);
                    // console.log("name", associeUnic);
                    // setFieldValue("assure", e.target.value);
                    changeUserDate(e, setFieldValue);
                    // console.log(
                    //   "id",
                    //   associeUnic[e.target.options[e.target.selectedIndex].id]
                    //     .date
                    // );
                    // const formattedDate = moment(
                    //   associeUnic[e.target.options[e.target.selectedIndex].id]
                    //     .date,
                    //   "DD/MM/YYYY"
                    // ).format("YYYY-MM-DD");
                    // setUserDate(formattedDate);
                  }}
                >
                  <option value={true}>Selectionner l'assure</option>
                  <option value="volvo">Moise Gaamy</option>
                  <option value="saab">Patrick</option>
                  <option value="mercedes">Valeres</option>
                  <option value="audi">Audi</option>
                  {associeUnic.map((item, index) => {
                    return (
                      // <option
                      //   key={item._id}
                      //   value={item.name}
                      //   id={index}
                      //   onClick={changeUserDate}
                      // >
                      //   {item.name}
                      // </option>
                      <>
                        <option value="volvo">Volvo</option>
                        <option value="saab">Saab</option>
                        <option value="mercedes">Mercedes</option>
                        <option value="audi">Audi</option>
                      </>
                    );
                  })}
                </select>
                <p className="mt-2 text-2xl text-red-600 dark:text-red-500">
                  <span className="font-medium">
                    {" "}
                    {errors.assure && touched.assure ? errors.assure : null}
                  </span>
                </p>
              </div>
            ) : (
              <div className="col-md-4 custom-with">
                <label
                  className="block text-grey-darker text-xl leading-3 mb-2"
                  htmlFor="assure"
                >
                  NOM ET PRENOMS <strong style={{ color: "#cc992d" }}>*</strong>
                </label>
                <Field
                  className="appearance-none border custom-with-label rounded text-xl font-mono w-full p-2 text-grey-darker"
                  name="assure"
                  id="assure"
                  type="text"
                  placeholder="NOM ET PRENOMS"
                  readOnly={defuntInfo}
                />
                <p className="text-red-600 dark:text-red-500">
                  {errors.assure && touched.assure ? errors.assure : null}
                </p>
              </div>
            )}
            {isChecked ? (
              <div className="col-md-4 custom-with">
                <label
                  className="block text-grey-darker text-xl leading-3 mb-2"
                  htmlFor="defDateNaissance"
                >
                  DATE DE NAISSANCE
                </label>
                <Field
                  className="appearance-none border custom-with-label rounded text-xl font-mono w-full p-2 text-grey-darker"
                  name="defDateNaissance"
                  id="defDateNaissance"
                  type="date"
                  placeholder="Date DE Naissance"
                  readOnly={defuntInfo}
                />
                <p className="text-red-600 dark:text-red-500">
                  {" "}
                  {errors.defDateNaissance && touched.defDateNaissance
                    ? errors.defDateNaissance
                    : null}
                </p>
              </div>
            ) : (
              <div className="col-md-4 custom-with">
                <label
                  className="block text-grey-darker text-xl leading-3 mb-2"
                  htmlFor="defDateNaissance"
                >
                  DATE DE NAISSANCE
                </label>
                <Field
                  className="appearance-none border custom-with-label rounded text-xl font-mono w-full p-2 text-grey-darker"
                  name="defDateNaissance"
                  id="defDateNaissance"
                  type="date"
                  placeholder="Date DE Naissance"
                />
                <p className="text-red-600 dark:text-red-500">
                  {" "}
                  {errors.defDateNaissance && touched.defDateNaissance
                    ? errors.defDateNaissance
                    : null}
                </p>
              </div>
            )}
            {isChecked ? (
              <div className="col-md-4 custom-with">
                <label
                  className="block text-grey-darker text-xl leading-3 mb-2"
                  htmlFor="defLieuNaissance"
                >
                  LIEU DE NAISSANCE
                </label>
                <Field
                  className="appearance-none border custom-with-label rounded text-xl font-mono w-full p-2 text-grey-darker"
                  name="defLieuNaissance"
                  id="defLieuNaissance"
                  type="text"
                  placeholder="LIEU DE NAISSANCE"
                />
                <p className="text-red-600 dark:text-red-500">
                  {" "}
                  {errors.defLieuNaissance && touched.defLieuNaissance
                    ? errors.defLieuNaissance
                    : null}
                </p>
              </div>
            ) : (
              <div className="col-md-4 custom-with">
                <label
                  className="block text-grey-darker text-xl leading-3 mb-2"
                  htmlFor="defLieuNaissance"
                >
                  LIEU DE NAISSANCE
                </label>
                <Field
                  className="appearance-none border custom-with-label rounded text-xl font-mono w-full p-2 text-grey-darker"
                  name="defLieuNaissance"
                  id="defLieuNaissance"
                  type="text"
                  placeholder="LIEU DE NAISSANCE"
                />
                <p className="text-red-600 dark:text-red-500">
                  {" "}
                  {errors.defLieuNaissance && touched.defLieuNaissance
                    ? errors.defLieuNaissance
                    : null}
                </p>
              </div>
            )}
            {/*</div>*/}
            {/*<div className="flex cutom-box">*/}
            <div className="col-md-4 custom-with">
              <label
                className="block text-grey-darker text-xl leading-3 mb-2"
                htmlFor="defProfession"
              >
                PROFESSION
              </label>
              <Field
                className="appearance-none border custom-with-label rounded text-xl font-mono w-full p-2 text-grey-darker"
                name="defProfession"
                id="defProfession"
                type="text"
                placeholder=" PROFESSION"
              />
              <p className="text-red-600 dark:text-red-500">
                {errors.defProfession && touched.defProfession
                  ? errors.defProfession
                  : null}
              </p>
            </div>
            <div className="col-md-4 custom-with">
              <label
                className="block text-grey-darker text-xl leading-3 mb-2"
                htmlFor="defDateDeces"
              >
                DATE DE DECES <strong style={{ color: "#cc992d" }}>*</strong>
              </label>
              <Field
                className="appearance-none border custom-with-label rounded text-xl font-mono w-full p-2 text-grey-darker"
                name="defDateDeces"
                id="defDateDeces"
                type="date"
                placeholder="DATE DE DECES"
              />
              <p className="text-red-600 dark:text-red-500">
                {" "}
                {errors.defDateDeces && touched.defDateDeces
                  ? errors.defDateDeces
                  : null}
              </p>
            </div>
            <div className="col-md-4 custom-with">
              <label
                className="block text-grey-darker text-xl leading-3 mb-2"
                htmlFor="defLieuDeces"
              >
                LIEU DU DECES
              </label>
              <Field
                className="appearance-none border custom-with-label rounded text-xl font-mono w-full p-2 text-grey-darker"
                name="defLieuDeces"
                id="defLieuDeces"
                type="text"
                placeholder="LIEU DU DECES"
              />
              <p className="text-red-600 dark:text-red-500">
                {" "}
                {errors.defLieuDeces && touched.defLieuDeces
                  ? errors.defLieuDeces
                  : null}
              </p>
            </div>
            {/*</div>*/}
            {/*<div className="flex cutom-box">*/}
            <div className="col-12 custom-with">
              <label
                className="text-grey-darker text-xl leading-3 mb-2"
                htmlFor="cause_deces"
              >
                CAUSES DE DECES
              </label>
              <textarea
                className="block appearance-none border custom-with-label rounded text-xl font-mono w-full p-2 text-grey-darker"
                name="cause_deces"
                id="cause_deces"
                placeholder="causes et circonstances présumées du décès"
              ></textarea>
              <p className="text-red-600 dark:text-red-500">
                {" "}
                {errors.cause_deces && touched.cause_deces
                  ? errors.cause_deces
                  : null}
              </p>
            </div>
          </div>
          <div className="flex mt-3 justify-between align-center text-center btn-container souscripteur-btn-mobile">
            <div className="w-1/2 mx-2 flex justify-center souscripteur-btn-back">
              <button
                onClick={() => handleBack(values)}
                type="button"
                className="return-btn flex recherche-btn justify-center items-center px-3 py-2 text-2xl font-medium rounded"
              >
                <AiOutlineArrowLeft className="mr-2" /> Précédent
              </button>
            </div>
            <div className="w-1/2 mx-2 flex justify-center">
              <button
                type="submit"
                className="flex recherche-btn justify-center items-center px-3 py-2 text-2xl font-medium text-white rounded border border-blue-700 focus:ring-4 focus:outline-none focus:bg-amber-400 hover:bg-amber-400 focus:bg-amber-400"
              >
                Suivant <AiOutlineArrowRight className="ml-2" />
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Defunt;

{
  /* <div className="w-1/2 ml-1 custom-with">
                        <label
                          className="block text-grey-darker custom-with-label text-3xl font-serif font-medium leading-3  mb-4"
                          htmlFor="assure"
                        >
                          ASSURE
                        </label>
                        <select
                          className="appearance-none border custom-with-label rounded  text-3xl font-mono w-11/12 h-20 py-2 px-3 text-grey-darker"
                          name="assure"
                          id="assure"
                          placeholder="ASSURE"
                          onChange={(e) => {
                            console.log("assure", e.target.value);
                            setFieldValue("assure", e.target.value);
                          }}
                        >
                          <option value={true}>Selectionner l'assure</option>
                          {ASSOCIE.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                        <p className="mt-2 text-2xl text-red-600 dark:text-red-500">
                          <span className="font-medium">
                            {" "}
                            {errors.assure && touched.assure
                              ? errors.assure
                              : null}
                          </span>
                        </p>
                      </div> */
}
//   cause_deces: Yup.string(),
//   // civilite_declarant: Yup.string().required('La civilite est obligatoire'),
//   assure: Yup.string().required("Lassure est obligatoire"),
//implement NOM_ASSURE_ASSOCIE
//getting data from localstorage
//   let data = JSON.parse(localStorage.getItem("data"));
//   // console.log('data=>', data.data.client.contrats)
//   let response = data.data.client.contrats;
//   // console.log('response =>', response)

//   let ASSOCIE = new Array();
//   let namebycontrat = response?.map((police) => {
//     return police.assures.map((associe) => {
//       return ASSOCIE.push(associe.NOM_ASSURE_ASSOCIE);
//     });
//     console.log("police", police.assures);
//   });
//   console.log("client contrats", ASSOCIE);
