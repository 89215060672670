import React, { useEffect, useState } from "react";
import "./header.css";
import { Link, useLocation } from "react-router-dom";
import { TfiWrite } from "react-icons/tfi";
import { MdFollowTheSigns, MdOutlineHelp } from "react-icons/md";
import "./assistance/assistanceContainer.css";
import { BsFillCalendar2CheckFill } from "react-icons/bs";
import { SiFiles } from "react-icons/si";
import { FaFileAudio } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import { RiFileSearchFill } from "react-icons/ri";
import { BsFillEnvelopeFill } from "react-icons/bs";

const Header = () => {
  const [navButton, setNavButton] = useState("");
  let location = useLocation();
  let locationPath = location.pathname;
  const declarerPath = ["/declare", "/search", "/signup", "/success"];
  const suivrePath = [
    "/login",
    "/newcode",
    "/suivrehome",
    "/piece",
    "/courierdecla",
    "/couriersini",
    "/allmailContainer",
    "/history",
  ];
  const assisterPath = [
    "/assistance",
    "/faq",
    "/rdv",
    "/rdvsuccess",
    "/guide",
    "/tutorial",
  ];
  let data = JSON.parse(localStorage.getItem("user"));
  console.log(data, "from header");

  useEffect(() => {
    if (declarerPath.includes(locationPath)) setNavButton("declaration");
    else if (suivrePath.includes(locationPath)) setNavButton("suivi");
    else if (assisterPath.includes(locationPath)) setNavButton("assistance");
  }, [locationPath, navButton]);

  return (
    <>
      <header id="header" className="fixed-top">
        <div className="container-fluid d-flex items-center justify-between">
          <div>
            <Link style={{ textDecoration: "none" }} to="/">
              <img
                src="https://winibuilder.com/file/project/wb100022/config/96c1ec2e-3481-4287-a4cd-b583bf17aca7.png"
                className="img-design"
                alt="sinistre_care"
              />
            </Link>
          </div>

          {locationPath === "/" ? (
            <span className="get-started-btn">SINISTRE TRACKER</span>
          ) : (
            <div className="get-started-btn">
              <Link
                to="/search"
                type="button"
                className={`btn bts-sm ${
                  navButton === "declaration"
                    ? "btn-custom"
                    : "btn-outline-light"
                } mx-1`}
                title="Déclarer un décès"
              >
                <span className="flex justify-center items-center">
                  <TfiWrite size={22} />
                  <label className="d-none d-md-inline m-1">Déclaration</label>
                </span>
              </Link>
              <Link
                to="/login"
                type="button"
                className={`btn bts-sm ${
                  navButton === "suivi" ? "btn-custom" : "btn-outline-light"
                } mx-1`}
                title="Suivre mon dossier"
              >
                <span className="flex justify-center items-center">
                  <MdFollowTheSigns size={22} />
                  <label className="d-none d-md-inline m-1">Suivi</label>
                </span>
              </Link>
              {navButton === "suivi" && locationPath !== "/login" ? (
                <Link to="/allmailContainer">
                  <button
                    type="button"
                    className="btn bts-sm btn-outline-light mx-1"
                    title="Courriers"
                  >
                    <BsFillEnvelopeFill
                      size={30}
                      className="d-none d-md-block"
                    />
                    <BsFillEnvelopeFill
                      size={22}
                      className="d-block d-md-none"
                    />
                  </button>

                  {data !== null && data.courriers.nouveaux > 0 ? (
                    <label className="no-underline relative inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-6 -left-5 dark:border-gray-900">
                      {data.courriers.nouveaux}
                    </label>
                  ) : (
                    ""
                  )}
                </Link>
              ) : null}
              <Link
                to="/assistance"
                type="button"
                className={`btn bts-sm ${
                  navButton === "assistance"
                    ? "btn-custom"
                    : "btn-outline-light"
                } mx-1`}
                title="Me faire assister"
              >
                <span className="flex justify-center items-center">
                  <MdOutlineHelp size={22} />
                  <label className="d-none d-md-inline m-1">Assistance</label>
                </span>
              </Link>
            </div>
          )}
        </div>
      </header>

      {navButton === "assistance" &&
        locationPath !== "/assistance" &&
        locationPath !== "/" && (
          <ul
            className="container nav nav-pills fixed-top"
            style={{ marginTop: "6rem", color: "#ffffff" }}
          >
            <li className="nav-item">
              <Link
                to="/faq"
                type="button"
                className={`btn bts-sm ${
                  locationPath === "/faq" ? "btn-custom" : "btn-outline-light"
                } m-2`}
                style={{ boxShadow: "2px 2px 2px 2px #e3e6e7" }}
              >
                <span className="flex justify-center items-center">
                  <RiFileSearchFill size={20} />
                  <label className="d-none d-md-inline m-1">
                    Foire aux questions
                  </label>
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/rdv"
                type="button"
                className={`btn bts-sm ${
                  locationPath === "/rdv" ? "btn-custom" : "btn-outline-light"
                } m-2`}
                style={{ boxShadow: "2px 2px 2px 2px #e3e6e7" }}
              >
                <span className="flex justify-center items-center">
                  <BsFillCalendar2CheckFill size={20} />
                  <label className="d-none d-md-inline m-1">Prendre rdv</label>
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/guide"
                type="button"
                className={`btn bts-sm ${
                  locationPath === "/guide" ? "btn-custom" : "btn-outline-light"
                } m-2`}
                style={{ boxShadow: "2px 2px 2px 2px #e3e6e7" }}
              >
                <span className="flex justify-center items-center">
                  <SiFiles size={20} />
                  <label className="d-none d-md-inline m-1">
                    Guide de production de pièces
                  </label>
                </span>
              </Link>
            </li>
            {/* <li className="nav-item">
                    <Link to="/tutorial" type="button" className={`btn bts-sm ${(locationPath === '/tutorial') ? "btn-custom" : "btn-outline-light"} m-2`} style={{ boxShadow: '2px 2px 2px 2px #e3e6e7' }}>
                        <span className="flex justify-center items-center">
                            <FaFileAudio size={20} />
                            <label className="d-none d-md-inline m-1">Tutoriel d'utilisation</label>
                        </span>
                    </Link>
                </li> */}
            <li className="nav-item">
              <Link
                to="/assistance"
                type="button"
                className={`btn bts-sm ${
                  navButton === "declaration"
                    ? "btn-custom"
                    : "btn-outline-light"
                } m-2`}
                style={{ boxShadow: "2px 2px 2px 2px #e3e6e7" }}
              >
                <span className="flex justify-center items-center">
                  <BiArrowBack size={20} />
                  <label className="d-none d-md-inline m-1">Retour</label>
                </span>
              </Link>
            </li>
          </ul>
        )}
    </>
  );
};

export default Header;
